import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./OurServices.css"
import { FormOutlined, KeyOutlined, BankOutlined, DesktopOutlined, CloudOutlined, GlobalOutlined, SlackOutlined } from "@ant-design/icons"
import { Link } from "react-router-dom"
import Contact from "../contact/Contact"
import { Helmet } from "react-helmet";

const OurServices = () => {

    const { t } = useTranslation()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div>
            <Helmet>
                <title> 1c программасини ўрнатиш, веб дастурлаш ва график дизайнерлик | MY PROGR@M</title>
                <meta name="description" content="1C программасини ўрнатиш, график дизайнерлик хизмати, веб сайт яратиш, веб дастурлаш, бизнесингизни мутахасислар билан ривожлантиринг" />
            </Helmet>
            <div className="container-fluid">
                <div className="row">
                    <div data-aos="zoom-in" className="col-12  forBacgroundOur">
                        <div data-aos="zoom-in" className="in_firstEffect">{t("ourServicesFirst")} <br /> </div>
                    </div>
                </div>
            </div >
            <div className="container mb-4 mt-4">
                <p data-aos="zoom-in" className="howToAvtoText">{t("ourServicesFirstTitle")}</p>
                <div className="row mt-5">
                    <div data-aos="zoom-in" className="col-12 col-md-6 col-sm-6 col-lg-4 col-xl-4 col-xxl-3">
                        <div className="card forOurBizines">
                            <div className="mb-4" style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                                <KeyOutlined style={{ color: "rgba(22, 47, 92, 0.836)" }} />
                            </div>
                            <div className="card_titlerOur">
                                {t("avtomatBiznes")}
                            </div>
                            <div className="more" style={{ width: "100%", display: "flex", justifyContent: "center", marginTop: "15px" }}>
                                <Link to="/our-services/ biznes">
                                    <button>{t("oneCLearnMore")}</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div data-aos="zoom-in" className="col-12 col-md-6 col-sm-6 col-lg-4 col-xl-4 col-xxl-3">
                        <div className="card forOurBizines">
                            <div className="mb-4" style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                                {/* <FormOutlined /> */}
                                <FormOutlined style={{ color: "rgba(22, 47, 92, 0.836)" }} />
                            </div>
                            <div className="card_titlerOur">
                                {t("oneCProduct")}
                            </div>
                            <div className="more" style={{ width: "100%", display: "flex", justifyContent: "center", marginTop: "15px" }}>
                                <Link to="/our-services/1 c programs">
                                    <button>{t("oneCLearnMore")}</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div data-aos="zoom-in" className="col-12 col-md-6 col-sm-6 col-lg-4 col-xl-4 col-xxl-3">
                        <div className="card forOurBizines">
                            <div className="mb-4" style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                                {/* <BankOutlined /> */}
                                <BankOutlined style={{ color: "rgba(22, 47, 92, 0.836)" }} />
                            </div>
                            <div className="card_titlerOur">
                                {t("oneCSanoat")}
                            </div>
                            <div className="more" style={{ width: "100%", display: "flex", justifyContent: "center", marginTop: "15px" }}>
                                <Link to="/our-services/ industry">
                                    <button>{t("oneCLearnMore")}</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div data-aos="zoom-in" className="col-12 col-md-4 col-sm-6 col-lg-4 col-xl-4 col-xxl-3">
                        <div className="card forOurBizines">
                            <div className="mb-4" style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                                {/* <CloudOutlined /> */}
                                <CloudOutlined style={{ color: "rgba(22, 47, 92, 0.836)" }} />
                            </div>
                            <div className="card_titlerOur">
                                {t("oneCITC")}
                            </div>
                            <div className="more" style={{ width: "100%", display: "flex", justifyContent: "center", marginTop: "15px" }}>
                                <Link to={'/our-services/its'}>
                                    <button>{t("oneCLearnMore")}</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div data-aos="zoom-in" className="col-12 col-md-4 col-sm-6 col-lg-4 col-xl-4 col-xxl-3">
                        <div className="card forOurBizines">
                            <div className="mb-4" style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                                {/* <DesktopOutlined /> */}
                                <CloudOutlined style={{ color: "rgba(22, 47, 92, 0.836)" }} />
                            </div>
                            <div className="card_titlerOur">
                                {t("oneCBitriks")}
                            </div>
                            <div className="more" style={{ width: "100%", display: "flex", justifyContent: "center", marginTop: "20px" }}>
                                <Link to={'/our-services/bitrix'}>
                                    <button>{t("oneCLearnMore")}</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div data-aos="zoom-in" className="col-12 col-md-4 col-sm-6 col-lg-4 col-xl-4 col-xxl-3">
                        <div className="card forOurBizines">
                            <div className="mb-4" style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                                {/* <ApartmentOutlined /> */}
                                {/* <GlobalOutlined /> */}
                                <GlobalOutlined style={{ color: "rgba(22, 47, 92, 0.836)" }} />
                            </div>
                            <div className="card_titlerOur">
                                {t("oneCWeb")}
                            </div>
                            <div className="more" style={{ width: "100%", display: "flex", justifyContent: "center", marginTop: "15px" }}>
                                <button>{t("oneCLearnMore")}</button>
                            </div>
                        </div>
                    </div>
                    <div data-aos="zoom-in" className="col-12 col-md-4 col-sm-6 col-lg-4 col-xl-4 col-xxl-3">
                        <div className="card forOurBizines">
                            <div className="mb-4" style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                                <SlackOutlined style={{ color: "rgba(22, 47, 92, 0.836)" }} />
                            </div>
                            <div className="card_titlerOur">
                                {t("oneCDesign")}
                            </div>
                            <div className="more" style={{ width: "100%", display: "flex", justifyContent: "center", marginTop: "15px" }}>
                                <button>{t("oneCLearnMore")}</button>
                            </div>
                        </div>
                    </div>
                    <div data-aos="zoom-in" className="col-12 col-md-6 col-sm-6 col-lg-4 col-xl-4 col-xxl-3">
                        <div className="card forOurBizines">
                            <div className="mb-4" style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                                {/* <DesktopOutlined /> */}
                                <DesktopOutlined style={{ color: "rgba(22, 47, 92, 0.836)" }} />
                            </div>
                            <div className="card_titlerOur">
                                {t("oneCWork")}
                            </div>
                            <div className="more" style={{ width: "100%", display: "flex", justifyContent: "center", marginTop: "20px" }}>
                                <button>{t("oneCLearnMore")}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <Contact />
            </div>
        </div>
    )
}

export default OurServices;