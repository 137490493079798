import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import CBugalteriya from "../../components/1CBugalteriya/1CBugalteriya";
import KompaniyaBoshqaruvi from "../../components/KompaniyaBoshqaruvi/KompaniyaBoshqaruvi";
import SavdoniBoshqaruvi from "../../components/SavdoniBoshqaruvi/SavdoniBoshqaruvi";
import Contact from "../contact/Contact";
import "./About1cMahsulotlari.css"
import Roznitsa from "../../components/Roznitsa/Roznitsa";
import DocumentAbarot from "../../components/DocumentAbarot/DocumentAbarot";
import Erpi from "../../components/Erpi/Erpi";
import { Helmet } from "react-helmet";

const About1CMahsulotlari = () => {

    const { t } = useTranslation()

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    return (
        <div>
            <Helmet>
                <title>MY PROGR@M | our-services</title>
                <meta name="description" content="Узбекистон буйича 1с программасини урнатамиз ва сифатли  хизмат корсатамиз. Корхонангизни биз билан автоматлаштиринг" />
            </Helmet>
            <div className="container mt-5 mb-4">
                <div className="row">
                    <p data-aos="zoom-in" className="howToAvtoText">{t('oneCProduct')}</p>
                    <div className="col-12">
                        <div data-aos="zoom-in" className="text-center clitsenziya">
                            {t("clitsenziya")}
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid forBachImgFather">
                <CBugalteriya />
            </div>
            <div className="container-fluid forBachImgFather">
                <KompaniyaBoshqaruvi />
            </div>
            <div className="container-fluid forBachImgFather">
                <SavdoniBoshqaruvi/>
            </div>
            <div className="container-fluid forBachImgFather">
                <Roznitsa/>
            </div>
            <div className="mb-4">
                <DocumentAbarot />
            </div>
            <div className="mb-4">
                <Erpi />
            </div>
            <div className="mb-4">
                <Contact />
            </div>
        </div>
    )
}

export default About1CMahsulotlari;