import React from "react";

const Cources = () =>{
    return(
        <div>
            <h1>Cources</h1>
        </div>
    )
}

export default Cources;