import React from "react";
import logo from "../../../assets/logoMP.png"
import "./Foter.css"
import {
  BsTelegram,
  BsFacebook,
  BsTwitter,
  BsInstagram,
  BsYoutube,
} from "react-icons/bs";
// import { useTranslation } from "react-i18next";

function Footer() {
//   const { t, i18n } = useTranslation();

  const thisYear = new Date().getFullYear();

  return (
    <div className="footer_wrap">
      <div className="footer_main container-fluid">
        <div className="row">
          <div className="col-lg-4 col-md-4 col-sm-12 col_style1">
            <div className="footer_logo_text_main">
              <div className="footer_logo">
                <img
                  src={logo}
                  alt="footer_logo"
                />
              </div>
              <p className="footer_logo_title">
              MY PROGR@M
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12 col_style2">
            <div className="bottom-contacts-address-text">
             
                

              <b>MANZILIMIZ</b>
              <p> Республика Узбекистан, Ташкентский обл,</p>
              <p> Город Бекабад Ёшлик Мфй, 14-мкр, 14-дом, 22-квартира </p>
              <p>
                <b>E-pochta:</b> MyProgramSales@gmail.com
              </p>
              <p>
                <b>Telefon:</b>+998 94 166 72 06
              </p>
              
            </div>
          </div>
          <div className="col-lg-4 col-md-12 col-sm-12  social_wrap col_style4">
            <div className="social">
              <a href="https://t.me/MyProgramSales" target={"_blank"}>
                <div className="social_icon">
                  <BsTelegram className="icon_color" />
                </div>
              </a>
              <a href="https://www.instagram.com/myprogram1c/" target={"_blank"}>
                <div className="social_icon">
                  <BsInstagram className="icon_color" />
                </div>
              </a>
              <a href="#">
                <div className="social_icon">
                  <BsFacebook className="icon_color" />
                </div>
              </a>
            </div>
            <div className="text_universitet">
              <p className="m-0">
                &copy; MY PROGRAM, {thisYear}. Barcha
                <br />
                huquqlar himoyalangan.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;