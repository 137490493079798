import React, { useEffect } from "react";
import "./contact.css"
import forContact from "../../assets/2.jpg"
import { CiPhone, CiInstagram } from "react-icons/ci";
import { PiTelegramLogoThin } from "react-icons/pi";
import Aos from "aos";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

const Contact = () => {

    useEffect(() => {
        Aos.init();
    }, [])

    const { t } = useTranslation()

    return (
        <div>
            <div className="container">
                <p data-aos="zoom-in" className="howToAvtoText">{t("contactTilt")}</p>
                <div className="row">
                    <div data-aos="zoom-in" className="col-md-6 col-sm-12">
                        <div>
                            <div className="card for_contact_icon">
                                <div style={{ width: "100%", display: "flex", justifyContent: "center" }}><CiPhone className="anticon" /></div> <hr />
                                <p className="for-phone">+998 94 166 72 06</p>
                            </div>
                            <div className="card for_contact_icon">
                                <a className="for_Soccial" href="https://www.instagram.com/myprogram1c/" target="blank">
                                    <div style={{ width: "100%", display: "flex", justifyContent: "center" }}><CiInstagram className="anticon" /></div> <hr />
                                    <p className="for-phone">myprogram1c</p>
                                </a>
                            </div>
                            <div className="card for_contact_icon">
                                <a className="for_Soccial" href="https://t.me/MyProgramSales" target="blank">
                                    <div style={{ width: "100%", display: "flex", justifyContent: "center" }}><PiTelegramLogoThin className="anticon" /></div>
                                    <hr />
                                    <p className="for-phone">https://t.me/MyProgramSales</p>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div data-aos="zoom-in" className="col-md-6 col-sm-12"><img className="img-fluid contactImg" src={forContact} alt="Contact" /></div>
                </div>
            </div>
        </div>

    )
}

export default Contact;