import React,{useEffect} from "react";
import Contact from "../contact/Contact";
import { useTranslation } from "react-i18next";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import RoznitsaAbout1 from "../../assets/RoznitsaAbout.png"
import RoznitsaAbout2 from "../../assets/RoznitsaAbout1.png"
import RoznitsaAbout3 from "../../assets/RoznitsaAbout3.png"
import RoznitsaAbout4 from "../../assets/RoznitsaAbout4.png"
import { FormOutlined, FileProtectOutlined, CodeOutlined, BookOutlined, VideoCameraOutlined, CheckOutlined, PhoneOutlined } from "@ant-design/icons"
import { Link } from "react-router-dom";

const DocumentAbarotAbout = () => {

    const { t } = useTranslation()

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    return (
        <div>

            <div className="container ">
                <p data-aos="zoom-in" className="howToAvtoText">1С: ДОКУМЕНТООБОРОТ ПРОФ </p>
                <div className="row" style={{ justifyContent: "center" }}>
                    <div className="col-10 mt-5">
                        <Carousel>
                            <div>
                                <img className="img-fluid" src={RoznitsaAbout1} />
                            </div>
                            <div>
                                <img className="img-fluid" src={RoznitsaAbout2} />
                            </div>
                            <div>
                                <img className="img-fluid" src={RoznitsaAbout3} />
                            </div>
                            <div>
                                <img className="img-fluid" src={RoznitsaAbout4} />
                            </div>
                        </Carousel>
                    </div>
                </div>
            </div>

            <div className="container mt-5">
                <p style={{ fontSize: "27px" }} data-aos="zoom-in" className="howToAvtoText">АВТОМАТИЗАЦИЯ ДОКУМЕНТООБОРОТА ПОЗВОЛИТ:</p>
                <div className="col-10">
                    <div className="mt-3 d-flex">
                        <p style={{ fontSize: "20px", color: "black", margin: "0 10px 0 0 " }}>- </p> <p style={{ fontSize: "20px", color: "black" }}>
                        систематизировать поток деловых документов в компании;
                        </p>
                    </div>
                    <div className="mt-3 d-flex">
                        <p style={{ fontSize: "20px", color: "black", margin: "0 10px 0 0 " }}>- </p>  <p style={{ fontSize: "20px", color: "black" }}>
                        свести к минимуму количество бумажных носителей;
                        </p>
                    </div>
                    <div className="mt-3 d-flex">
                        <p style={{ fontSize: "20px", color: "black", margin: "0 10px 0 0 " }}>- </p>  <p style={{ fontSize: "20px", color: "black" }}>
                        в разы ускорить согласование договоров;
                        </p>
                    </div>
                    <div className="mt-3 d-flex">
                        <p style={{ fontSize: "20px", color: "black", margin: "0 10px 0 0 " }}>- </p>  <p style={{ fontSize: "20px", color: "black" }}>
                        обеспечить 100% сохранность всей информации;
                        </p>
                    </div>
                    <div className="mt-3 d-flex">
                        <p style={{ fontSize: "20px", color: "black", margin: "0 10px 0 0 " }}>- </p>  <p style={{ fontSize: "20px", color: "black" }}>
                        создать единую базу документов с мгновенным поиском;
                        </p>
                    </div>
                    <div className="mt-3 d-flex">
                        <p style={{ fontSize: "20px", color: "black", margin: "0 10px 0 0 " }}>- </p>  <p style={{ fontSize: "20px", color: "black" }}>
                        организовать удобную работу сотрудников с документами;
                        </p>
                    </div>
                    <div className="mt-3 d-flex">
                        <p style={{ fontSize: "20px", color: "black", margin: "0 10px 0 0 " }}>- </p>  <p style={{ fontSize: "20px", color: "black" }}>
                        контролировать исполнение всех договоров и поручений;
                        </p>
                    </div>
                    <div className="mt-3 d-flex">
                        <p style={{ fontSize: "20px", color: "black", margin: "0 10px 0 0 " }}>- </p>  <p style={{ fontSize: "20px", color: "black" }}>
                        снизить риск ошибок или путаницы в документах и повысить качество материалов.
                        </p>
                    </div>
                </div>
            </div>

            <div style={{ padding: "0 50px" }} className="container mt-5">
                <p style={{ fontSize: "27px" }} data-aos="zoom-in" className="howToAvtoText mb-5">ВОЗМОЖНОСТИ 1С: ДОКУМЕНТООБОРОТ ПРОФ</p>
                <div className="mt-3">
                    <CheckOutlined style={{ fontSize: "30px", margin: "0 10px 0 0", color: "rgba(22, 47, 92, 0.836)" }} />  <b style={{ fontSize: "25px", fontWeight: "bold", color: "rgba(22, 47, 92, 0.836)" }}>
                    Комплексная работа с договорами 
                    </b>
                    <p style={{ fontSize: "20px", color: "black", marginLeft: "40px" }}>
                    «1С:Документооборот» автоматизирует каждый шаг по работе с договорами: подготовка или создание типового документа по шаблону, согласование и контроль
                     исполнения обязательств. Электронный документооборот помогает существенно сэкономить время сотрудников, сроки согласования документов в среднем ускоряются в 2-3 раза. 
                    </p>

                </div>
                <div className="mt-5">
                    <CheckOutlined style={{ fontSize: "30px", margin: "0 10px 0 0", color: "rgba(22, 47, 92, 0.836)" }} />
                    <b style={{ fontSize: "25px", fontWeight: "bold", color: "rgba(22, 47, 92, 0.836)" }}>Учет и управление документами  </b>
                    <p style={{ fontSize: "20px", color: "black", marginLeft: "40px" }}>
                    «1С:Документооборот» ведет учет всей документации: входящих и исходящих писем, обращений от физических лиц, шаблонов приказов, актов и т.д. Помимо этого, в «1С:Документооборот» удобно 
                    вести повседневную работу с внутренними документами. Функционал «1С:Документооборот» включает электронную подпись для согласования и защиты документов. 
                    </p>
                </div>
                <div className="mt-5">
                    <CheckOutlined style={{ fontSize: "30px", margin: "0 10px 0 0", color: "rgba(22, 47, 92, 0.836)" }} />
                    <b style={{ fontSize: "25px", fontWeight: "bold", color: "rgba(22, 47, 92, 0.836)" }}>Централизованное безопасное хранение документов </b>
                    <p style={{ fontSize: "20px", color: "black", marginLeft: "40px" }}>
                    В «1С:Документооборот» можно хранить не только документы любых форматов, а также аудио, видео, презентации, изображения. 
                    </p>
                </div>
                <div className="mt-5">
                    <CheckOutlined style={{ fontSize: "30px", margin: "0 10px 0 0", color: "rgba(22, 47, 92, 0.836)" }} />
                    <b style={{ fontSize: "25px", fontWeight: "bold", color: "rgba(22, 47, 92, 0.836)" }}>Быстрый поиск и обработка  </b>
                    <p style={{ fontSize: "20px", color: "black", marginLeft: "40px" }}>
                    Важное преимущество программы «1С:Документооборот» — быстрый поиск по базе. В «1С:Документооборот» можно мгновенно 
                    найти любой документ не только по реквизиту, но и по содержанию. Это существенно сэкономит время сотрудников. 
                    </p>
                </div>
                <div className="mt-5">
                    <CheckOutlined style={{ fontSize: "30px", margin: "0 10px 0 0", color: "rgba(22, 47, 92, 0.836)" }} />
                    <b style={{ fontSize: "25px", fontWeight: "bold", color: "rgba(22, 47, 92, 0.836)" }}>Эффективное управление процессами  </b>
                    <p style={{ fontSize: "20px", color: "black", marginLeft: "40px" }}>
                    «1С:Документооборот» содержит готовые шаблоны для различных бизнес-процессов: согласование, рассмотрение, утверждение и других.
                     Для каждого процесса есть своя схема работы, которую легко настроить под индивидуальные задачи компании. 
                    </p>
                    <p></p>
                </div>
                <div className="mt-5">
                    <CheckOutlined style={{ fontSize: "30px", margin: "0 10px 0 0", color: "rgba(22, 47, 92, 0.836)" }} />
                    <b style={{ fontSize: "25px", fontWeight: "bold", color: "rgba(22, 47, 92, 0.836)" }}>Почтовый клиент </b>
                    <p style={{ fontSize: "20px", color: "black", marginLeft: "40px" }}>
                    Встроенная полноценная корпоративная почта в программе «1С:Документооборот» поможет усилить контроль исполнения 
                    писем с задачами руководителей, сократить время на поиск и обработку входящих писем, а также создание новых.  
                    </p>
                </div>
                <div className="mt-5">
                    <CheckOutlined style={{ fontSize: "30px", margin: "0 10px 0 0", color: "rgba(22, 47, 92, 0.836)" }} />
                    <b style={{ fontSize: "25px", fontWeight: "bold", color: "rgba(22, 47, 92, 0.836)" }}>Контроль задач </b>
                    <p style={{ fontSize: "20px", color: "black", marginLeft: "40px" }}>
                    «1С:Документооборот» включает механизмы контроля исполнительской дисциплины, задач и поручений с 
                    встроенными отчетами для руководителей. Это позволяет оперативно оценивать качество работы и нагрузку сотрудников. 
                    </p>
                </div>
                <div className="mt-5">
                    <CheckOutlined style={{ fontSize: "30px", margin: "0 10px 0 0", color: "rgba(22, 47, 92, 0.836)" }} />
                    <b style={{ fontSize: "25px", fontWeight: "bold", color: "rgba(22, 47, 92, 0.836)" }}>Совместная работа  </b>
                    <p style={{ fontSize: "20px", color: "black", marginLeft: "40px" }}>
                    «1С:Документооборот» облегчает совместную работу и взаимодействие сотрудников. Поддерживается коллективный доступ к файлам как для просмотра,
                     так и для редактирования с разграничением прав доступа. При этом благодаря механизму блокировки файлов конфликты при одновременном 
                     редактировании документов в «1С:Документооборот» исключаются. 
                    </p>
                </div>
                <div className="mt-5">
                    <CheckOutlined style={{ fontSize: "30px", margin: "0 10px 0 0", color: "rgba(22, 47, 92, 0.836)" }} />
                    <b style={{ fontSize: "25px", fontWeight: "bold", color: "rgba(22, 47, 92, 0.836)" }}>Мероприятия </b>
                    <p style={{ fontSize: "20px", color: "black", marginLeft: "40px" }}>
                    С помощью «1С:Документооборот» удобно планировать, проводить и анализировать эффективность внутренних
                     и внешних мероприятий. В «1С:Документооборот» есть функционал брони переговорных, подбора удобного для участников времени, 
                     формирования программы, создания протокола по итогам мероприятия и контроля принятых поручений. 
                    </p>
                </div>
                <div className="mt-5">
                    <CheckOutlined style={{ fontSize: "30px", margin: "0 10px 0 0", color: "rgba(22, 47, 92, 0.836)" }} />
                    <b style={{ fontSize: "25px", fontWeight: "bold", color: "rgba(22, 47, 92, 0.836)" }}>Мобильный доступ  </b>
                    <p style={{ fontSize: "20px", color: "black", marginLeft: "40px" }}>
                    В «1С:Документооборот» можно зайти с планшета или смартфона благодаря бесплатному мобильному клиенту. Программа также поддерживает
                     удаленный доступ и подключение через веб-браузер. Это позволит сотрудникам оперативно просмотреть почту, отправить письмо
                      коллегам или клиентам, поставить задачу в любом удобном месте, где есть Интернет. 
                    </p>
                </div>
                <div className="mt-5">
                    <CheckOutlined style={{ fontSize: "30px", margin: "0 10px 0 0", color: "rgba(22, 47, 92, 0.836)" }} />
                    <b style={{ fontSize: "25px", fontWeight: "bold", color: "rgba(22, 47, 92, 0.836)" }}>Бесшовная интеграция с «1С»  </b>
                    <p style={{ fontSize: "20px", color: "black", marginLeft: "40px" }}>
                    Вы можете купить «1С:Документооборот» и легко объединить его с другими решениями «1С:Предприятия», обеспечив автоматическую 
                    синхронизацию данных. Бесшовная интеграция избавит сотрудников от необходимости переключаться между программами и повторно вводить данные. 
                    </p>
                </div>
            </div>

            <div>
                <Contact />
            </div>
        </div>
    )
}

export default DocumentAbarotAbout;