import React,{useEffect} from "react";
import Contact from "../contact/Contact";
import { useTranslation } from "react-i18next";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import RoznitsaAbout1 from "../../assets/RoznitsaAbout.png"
import RoznitsaAbout2 from "../../assets/RoznitsaAbout1.png"
import RoznitsaAbout3 from "../../assets/RoznitsaAbout3.png"
import RoznitsaAbout4 from "../../assets/RoznitsaAbout4.png"
import { FormOutlined, FileProtectOutlined, CodeOutlined, BookOutlined, VideoCameraOutlined, CheckOutlined, PhoneOutlined } from "@ant-design/icons"
import { Link } from "react-router-dom";

const RoznitsaAbout = () => {

    const { t } = useTranslation()


    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <div>

            <div className="container ">
                <p data-aos="zoom-in" className="howToAvtoText">1С: РОЗНИЦА 8 ДЛЯ УЗБЕКИСТАНА. ЭЛЕКТРОННАЯ ПОСТАВКА </p>
                <div className="row" style={{ justifyContent: "center" }}>
                    <div className="col-10 mt-5">
                        <Carousel>
                            <div>
                                <img className="img-fluid" src={RoznitsaAbout1} />
                            </div>
                            <div>
                                <img className="img-fluid" src={RoznitsaAbout2} />
                            </div>
                            <div>
                                <img className="img-fluid" src={RoznitsaAbout3} />
                            </div>
                            <div>
                                <img className="img-fluid" src={RoznitsaAbout4} />
                            </div>
                        </Carousel>
                    </div>
                </div>
            </div>

            <div className="container mt-5">
                <p style={{ fontSize: "27px" }} data-aos="zoom-in" className="howToAvtoText">ВНЕДРЕНИЕ ПРОГРАММЫ «1С:РОЗНИЦА 8» ПОЗВОЛИТ:</p>
                <div className="col-10">
                    <div className="mt-3 d-flex">
                        <p style={{ fontSize: "20px", color: "black", margin: "0 10px 0 0 " }}>- </p> <p style={{ fontSize: "20px", color: "black" }}>
                            вести учет товаров различных типов;
                        </p>
                    </div>
                    <div className="mt-3 d-flex">
                        <p style={{ fontSize: "20px", color: "black", margin: "0 10px 0 0 " }}>- </p>  <p style={{ fontSize: "20px", color: "black" }}>
                            управлять маркетинговыми акциями и скидками;
                        </p>
                    </div>
                    <div className="mt-3 d-flex">
                        <p style={{ fontSize: "20px", color: "black", margin: "0 10px 0 0 " }}>- </p>  <p style={{ fontSize: "20px", color: "black" }}>
                            управлять ассортиментом и ценообразованием сети магазинов;
                        </p>
                    </div>
                    <div className="mt-3 d-flex">
                        <p style={{ fontSize: "20px", color: "black", margin: "0 10px 0 0 " }}>- </p>  <p style={{ fontSize: "20px", color: "black" }}>
                            подключить любое торговое оборудование: кассовые аппараты, дисплеи, сканеры, терминалы, электронные весы и др.;
                        </p>
                    </div>
                    <div className="mt-3 d-flex">
                        <p style={{ fontSize: "20px", color: "black", margin: "0 10px 0 0 " }}>- </p>  <p style={{ fontSize: "20px", color: "black" }}>
                            управлять запасами, закупками и складом;
                        </p>
                    </div>
                    <div className="mt-3 d-flex">
                        <p style={{ fontSize: "20px", color: "black", margin: "0 10px 0 0 " }}>- </p>  <p style={{ fontSize: "20px", color: "black" }}>
                            контролировать работу персонала;
                        </p>
                    </div>
                    <div className="mt-3 d-flex">
                        <p style={{ fontSize: "20px", color: "black", margin: "0 10px 0 0 " }}>- </p>  <p style={{ fontSize: "20px", color: "black" }}>
                            вести отчетность и многое другое!
                        </p>
                    </div>
                </div>
            </div>

            <div style={{ padding: "0 50px" }} className="container mt-5">
                <p style={{ fontSize: "27px" }} data-aos="zoom-in" className="howToAvtoText mb-5">ВОЗМОЖНОСТИ 1С:РОЗНИЦА 8 </p>
                <div className="mt-3">
                    <CheckOutlined style={{ fontSize: "30px", margin: "0 10px 0 0", color: "rgba(22, 47, 92, 0.836)" }} />  <b style={{ fontSize: "25px", fontWeight: "bold", color: "rgba(22, 47, 92, 0.836)" }}>
                    Управление продажами 
                    </b>
                    <p style={{ fontSize: "20px", color: "black", marginLeft: "40px" }}>
                    В «1С:Розница» разработан интерфейс для рабочего места кассира, который содержит 26 команд. С их помощью можно настроить технологию продаж с учетом конкретной отрасли. 
                    Гибко настраиваемый и понятный интерфейс позволит кассиру быстро оформить покупку. 
                    </p>

                </div>
                <div className="mt-5">
                    <CheckOutlined style={{ fontSize: "30px", margin: "0 10px 0 0", color: "rgba(22, 47, 92, 0.836)" }} />
                    <b style={{ fontSize: "25px", fontWeight: "bold", color: "rgba(22, 47, 92, 0.836)" }}>Маркетинговые акции и скидки  </b>
                    <p style={{ fontSize: "20px", color: "black", marginLeft: "40px" }}>
                    «1С:Розница» позволяет собирать и анализировать данные о предпочтениях клиентов и формировать на их основе эффективные дисконтные 
                    программы и маркетинговые акции. 
                    </p>
                </div>
                <div className="mt-5">
                    <CheckOutlined style={{ fontSize: "30px", margin: "0 10px 0 0", color: "rgba(22, 47, 92, 0.836)" }} />
                    <b style={{ fontSize: "25px", fontWeight: "bold", color: "rgba(22, 47, 92, 0.836)" }}>Управление запасами и закупками  </b>
                    <p style={{ fontSize: "20px", color: "black", marginLeft: "40px" }}>
                    Программа позволяет составлять заказы поставщикам на основе анализа продаж и текущих товарных остатков, планировать и контролировать оплату поставок. 
                    </p>
                </div>
                <div className="mt-5">
                    <CheckOutlined style={{ fontSize: "30px", margin: "0 10px 0 0", color: "rgba(22, 47, 92, 0.836)" }} />
                    <b style={{ fontSize: "25px", fontWeight: "bold", color: "rgba(22, 47, 92, 0.836)" }}>Склад </b>
                    <p style={{ fontSize: "20px", color: "black", marginLeft: "40px" }}>
                    Данное решение поможет поддерживать товарные остатки магазина в актуальном состоянии. Благодаря штрихкодированию товаровед сможет 
                    точно учитывать движения товаров по складам. 
                    </p>
                </div>
                <div className="mt-5">
                    <CheckOutlined style={{ fontSize: "30px", margin: "0 10px 0 0", color: "rgba(22, 47, 92, 0.836)" }} />
                    <b style={{ fontSize: "25px", fontWeight: "bold", color: "rgba(22, 47, 92, 0.836)" }}>Управление персоналом  </b>
                    <p style={{ fontSize: "20px", color: "black", marginLeft: "40px" }}>
                    С помощью «1С:Розница 8» управляющий магазином может планировать рабочие смены, регистрировать отработанное время и переработки. В программе также 
                    поддерживается учет личных продаж сотрудников, что позволит ввести эффективную систему мотивации. 
                    </p>
                    <p></p>
                </div>
                <div className="mt-5">
                    <CheckOutlined style={{ fontSize: "30px", margin: "0 10px 0 0", color: "rgba(22, 47, 92, 0.836)" }} />
                    <b style={{ fontSize: "25px", fontWeight: "bold", color: "rgba(22, 47, 92, 0.836)" }}>Многофирменный учет </b>
                    <p style={{ fontSize: "20px", color: "black", marginLeft: "40px" }}>
                    «1С:Розница» поддерживает многофирменный учет. Каждый склад или торговый зал в программе можно отнести к определенной организации. 
                    </p>
                </div>
                <div className="mt-5">
                    <CheckOutlined style={{ fontSize: "30px", margin: "0 10px 0 0", color: "rgba(22, 47, 92, 0.836)" }} />
                    <b style={{ fontSize: "25px", fontWeight: "bold", color: "rgba(22, 47, 92, 0.836)" }}>Ценообразование</b>
                    <p style={{ fontSize: "20px", color: "black", marginLeft: "40px" }}>
                    Конфигурация обеспечивает централизованное регулирование розничных цен для каждого магазина. В то же время в каждой розничной 
                    точке можно корректировать ценообразование в зависимости от ее расположения, наличия конкурентов и других факторов. 
                    </p>
                </div>
                <div className="mt-5">
                    <CheckOutlined style={{ fontSize: "30px", margin: "0 10px 0 0", color: "rgba(22, 47, 92, 0.836)" }} />
                    <b style={{ fontSize: "25px", fontWeight: "bold", color: "rgba(22, 47, 92, 0.836)" }}>Торговое оборудование </b>
                    <p style={{ fontSize: "20px", color: "black", marginLeft: "40px" }}>
                    К системе «1С:Розница» можно подключить любое торговое оборудование: кассовые аппараты, дисплеи, сканеры, терминалы, электронные весы 
                    и др. Также поддерживается работа с внешними устройствами в торговом зале. 
                    </p>
                </div>
                <div className="mt-5">
                    <CheckOutlined style={{ fontSize: "30px", margin: "0 10px 0 0", color: "rgba(22, 47, 92, 0.836)" }} />
                    <b style={{ fontSize: "25px", fontWeight: "bold", color: "rgba(22, 47, 92, 0.836)" }}>Интеграция с «1С» </b>
                    <p style={{ fontSize: "20px", color: "black", marginLeft: "40px" }}>
                    «1С:Розница» легко интегрируется с другими решениями «1С». Например, в качестве управляющей системы можно использовать «1С:Управление торговлей». Это позволит разделить управляющие
                     функции центрального офиса и исполняющие функции сотрудников магазина. Также поддерживается передача данных в «1С:Бухгалтерию». 
                    </p>
                </div>
                <div className="mt-5">
                    <CheckOutlined style={{ fontSize: "30px", margin: "0 10px 0 0", color: "rgba(22, 47, 92, 0.836)" }} />
                    <b style={{ fontSize: "25px", fontWeight: "bold", color: "rgba(22, 47, 92, 0.836)" }}>Работа с РИБ </b>
                    <p style={{ fontSize: "20px", color: "black", marginLeft: "40px" }}>
                    «1С:Розница» поддерживает работу с территориально распределенными информационными базами (РИБ) с четким разделением документооборота по магазинам. 
                    </p>
                </div>
            </div>

            <div className="container mb-4 mt-4">
                <p data-aos="zoom-in" className="howToAvtoText">БЕСПЛАТНО ПРИ ПОКУПКЕ</p>
                <div className="row mt-5" style={{ justifyContent: "center" }}>
                    <div data-aos="zoom-in" className="col-12 col-md-4 col-sm-6 col-lg-4 col-xl-4 col-xxl-4">
                        <div style={{ width: "100%" }} className="card forOurBizines">
                            <div className="mb-4" style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                                <FileProtectOutlined style={{ color: "rgba(22, 47, 92, 0.836)" }} />
                            </div>
                            <div className="card_titlerOur">
                                {t("freeBuy")}
                            </div>

                        </div>
                    </div>



                    <div data-aos="zoom-in" className="col-12 col-md-4 col-sm-6 col-lg-4 col-xl-4 col-xxl-4">
                        <div className="card forOurBizines">
                            <div className="mb-4" style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                                <BookOutlined style={{ color: "rgba(22, 47, 92, 0.836)" }} />
                            </div>
                            <div className="card_titlerOur">
                                {t("freeBuy5R")}
                            </div>

                        </div>
                    </div>

                </div>
            </div>

            <div>
                <Contact />
            </div>
        </div>
    )
}

export default RoznitsaAbout;