import React from "react";
import product3 from "../../assets/product3.png"
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const SavdoniBoshqaruvi = () =>{

    const { t } = useTranslation()
    const navigate = useNavigate()

    return(
        <div>
            <div className="row forBacground3 mt-5 mb-5 ">
                    <div className="col-12">
                        <div className="ONEcBugalter">
                            <div data-aos="zoom-in" className="ONEcBugalterIN">
                                <div><p className="oneCAccountingText">{t("howToAvtoText2")}</p>
                                <p className="oneCAccountingText2">{t("oneCAccountingText3")}</p>
                                <button onClick={()=>navigate('/our-services/trade management')} className="btn btn-primary">{t("oneCLearnMore")}</button>
                                </div>
                            </div>
                            <div><img data-aos="zoom-in" className="img-fluid forImageBlur" src={product3} alt="Product 1"/></div>
                        </div>
                    </div>
                    {/* <div className="col-md-6 col-sm-12">
                        
                        </div> */}
                </div>
        </div>
    )
}

export default SavdoniBoshqaruvi;