import React, { useState,useEffect } from 'react'
import '../../../node_modules/react-modal-video/scss/modal-video.scss';
import ReactDOM from 'react-dom'
import ModalVideo from 'react-modal-video'
import Item from 'antd/es/list/Item';
import { useTranslation } from 'react-i18next';
import Contact from '../contact/Contact';

const ForVideo = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    const [isOpen, setOpen] = useState(false)
    const [isOpen2, setOpen2] = useState(false)
    const [isOpen3, setOpen3] = useState(false)
    const [isOpen4, setOpen4] = useState(false)
    const [isOpen5, setOpen5] = useState(false)
    const [video, setVideo] = useState([
        { number: "1.1", name: "Денежные средства", videoID: "GQjdLJeyces?si=T3eIlAVbw83wNq-k" },
        { number: "1.2", name: "Взаиморасчеты с контрагентами", videoID: "KZSe_Rd4UfE?si=q_r93cVJaR26zzv1" },
        { number: "1.3", name: "Основные средства", videoID: "jdzdSRUpLBI?si=q8fuvVPvuAxgjD5S" },
        { number: "1.4", name: "Готовая продукция", videoID: "W-BjbqW-Pws" },
        { number: "1.5", name: "Взаиморасчеты с сотрудниками", videoID: "6xvjxuP8Xmg" },
        { number: "1.6", name: "Нематериальные активы", videoID: "rc4vz2IAFHA" },
        { number: "1.7", name: "Розничная торговля АТТ, НТТ", videoID: "VCgXSf1FJE8" },
        { number: "1.8", name: "Как самостоятельно сделать обновление в 1С редакция 3.0", videoID: "N_YQBbDUOMc" },
        { number: "1.9", name: "Как сделать начальные настройки в 1С редакция 3.0", videoID: "4lq18LXkRNI" },
        { number: "1.10", name: "Как добавить организацию в 1С редакция 3.0", videoID: "ne7uENOWVc8" },
        { number: "1.11", name: "Как настроить учетную политику организации и вид деятельности в 1С редакция 3.0. Часть 1", videoID: "1XG7u6JpLZs" },
        { number: "1.12", name: "Как настроить учетную политику организации и вид деятельности в 1С редакция 3.0. Часть 2", videoID: "L2bo8AKhb70" },
    ])
    const [video2, setVideo2] = useState([
        { number: "2.1", name: "Начальные настройки для работы с банком", videoID: "UV_MPyzy1Dc" },
        { number: "2.2", name: "Создание платежного поручения", videoID: "zzMBiBznHDE" },
        { number: "2.3", name: "Поступление денежных средств на расчетный счет от покупателей", videoID: "ZVbh9JGDGHQ" },
        { number: "2.4", name: "Поступление денежных средств на расчетный счет от продаж по платежным картам", videoID: "NmoL3seAqQ4" },
        { number: "2.5", name: "Списание денежных средств с расчетного счета поставщику", videoID: "HMqE5xbS52o" },
        { number: "2.6", name: "Приобретение иностранной валюты", videoID: "CPU3yJlzq_U" },
        { number: "2.7", name: "Продажа иностранной валюты", videoID: "vgGwrFJMyNc" },
        { number: "2.8", name: "Конверсия иностранной валюты по кросс-курсу", videoID: "MHTT5jDszKU" },
        { number: "2.9", name: "Выплата заработной платы сотрудникам по ведомостям, индивидуально, по договорам ГПХ", videoID: "kY3H8hOOhCQ" },
        { number: "2.10", name: "Перечисление налогов, ИНПС, НДС, НДС с услуг нерезидентов", videoID: "HCpsxYmgiAs" },
        { number: "2.11", name: "Как самостоятельно настроить обмен с банком через Dibank.", videoID: "9tKKBG7Xp2U" },
    ])
    const [video3, setVideo3] = useState([
        { number: "3.1", name: " Как оформить выплату заработной платы по ведомостям, сотруднику и по договорам ГПХ через кассу", videoID: "JbADGUj6Ln4" },
        { number: "3.2", name: "Как оформить инкассацию и расходный кассовый ордер с видом прочее", videoID: "iWWzIpjHuOs" },
        { number: "3.3", name: "Как оформить приход розничной выручки в кассу. Формирование кассовой книги", videoID: "l40SjzSsgRE" },
        { number: "3.4", name: "Как оформить приход прочей выручки, получение наличных в банке, возврат денежных средств сотрудником и подотчетными лицами", videoID: "_OrKajRuYNA" },
        { number: "3.5", name: "Как оформить поступление ж/д(авиа) билетов от поставщиков, подотчетных лиц, прочий приход", videoID: "XY961CvLtfc" },
        { number: "3.6", name: "Как оформить выдачу ж/д(авиа) билетов подотчетным лицам, возврат поставщикам, прочий расход", videoID: "erTpdwU445o" },
    ])
    const [video4, setVideo4] = useState([
        { number: "4.1", name: " Как оформить командировку. (Пошаговая инструкция от кадрового документа до авансового отчета)", videoID: "SdgixfioErE" },
        { number: "4.2", name: "Как оформить учет по корпоративной карте через авансовый отчет", videoID: "TabBnphPMUk" },
        { number: "4.3", name: "Как оформить учет по корпоративной карте через документ Покупки по корпоративной карте", videoID: "Ve4LiVKNSAY" },
    ])
    const [video5, setVideo5] = useState([
        { number: "5.1", name: " Как оформить доверенность на получение товарно-материальных ценностей", videoID: "ltBVcIIPl3w" },
        { number: "5.2", name: "Как оформить поступление товарно-материальных ценностей. Справка расчет себестоимости партии", videoID: "CIP05Z_WZhw?si=JOtBHFz7ZqXUFYX9" },
        { number: "5.3", name: "Как оформить поступление услуг от местных поставщиков, начисление НДС с услуг нерезидента", videoID: "wGf4s2oulrM" },
        { number: "5.4", name: "Как оформить поступление дополнительных расходов на ТМЦ. Часть 1", videoID: "62NU1ONgzh8" },
        { number: "5.5", name: "Как оформить поступление дополнительных расходов на ТМЦ. Часть 2", videoID: "iZ7mnkj2IJE" },
        { number: "5.6", name: "Как оформить приход ТМЦ от иностранных поставщиков и ГТД по импорту", videoID: "LrwtEBD5YF8" },
        { number: "5.7", name: "Как оформить поступление товаров от Комитента и реализовать их Покупателю", videoID: "jDSXc9tgtSk" },
        { number: "5.8", name: "Как оформить Отчет Комитентам о продажах", videoID: "fzfIVx_bVmY" },
        { number: "5.9", name: "Как оформить возврат ТМЦ поставщикам", videoID: "MCtkviiqBSc" },
        { number: "5.10", name: "Как оформить счет от поставщика", videoID: "xIaimvHLrYw" },
        { number: "5.11", name: "Как оформить путевой лист и списать топливо", videoID: "zasKMGy_Pig" },
        { number: "5.12", name: "Как создать Акт-сверки", videoID: "AtrLn2W8RW8" },
        { number: "5.13", name: "Как создать Корректировку Долга", videoID: "--40NzreayU" },
        { number: "5.14", name: "Как оформить корректировочный счет-фактуру от поставщика", videoID: "_WUmCFHTNpE" },
        { number: "5.15", name: "Как оформить поступление расходов будущих периодов", videoID: "Ve4LiVKNSAY" },
        { number: "5.16", name: "Как оформить учет по корпоративной карте через документ Покупки по корпоративной карте", videoID: "Ve4LiVKNSAY" },
    ])
    const [openVideo, setOpenVideo] = useState([])
    const [openVideo2, setOpenVideo2] = useState([])
    const [openVideo3, setOpenVideo3] = useState([])
    const [openVideo4, setOpenVideo4] = useState([])
    const [openVideo5, setOpenVideo5] = useState([])

    const selectVideo = (auhtorName) => {
        setOpenVideo(video.filter(val => val.name === auhtorName))
        setOpen(true)
    }

    const selectVideo2 = (auhtorName) => {
        setOpenVideo2(video2.filter(val => val.name === auhtorName))
        setOpen2(true)
    }
    const selectVideo3 = (auhtorName) => {
        setOpenVideo3(video3.filter(val => val.name === auhtorName))
        setOpen3(true)
    }
    const selectVideo4 = (auhtorName) => {
        setOpenVideo4(video4.filter(val => val.name === auhtorName))
        setOpen4(true)
    }
    const selectVideo5 = (auhtorName) => {
        setOpenVideo5(video5.filter(val => val.name === auhtorName))
        setOpen5(true)
    }

    const { t } = useTranslation()

    return (
        <div>
            <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId={openVideo[0]?.videoID} onClose={() => setOpen(false)} />
            <ModalVideo channel='youtube' autoplay isOpen={isOpen2} videoId={openVideo2[0]?.videoID} onClose={() => setOpen2(false)} />
            <ModalVideo channel='youtube' autoplay isOpen={isOpen3} videoId={openVideo3[0]?.videoID} onClose={() => setOpen3(false)} />
            <ModalVideo channel='youtube' autoplay isOpen={isOpen4} videoId={openVideo4[0]?.videoID} onClose={() => setOpen4(false)} />
            <ModalVideo channel='youtube' autoplay isOpen={isOpen5} videoId={openVideo5[0]?.videoID} onClose={() => setOpen5(false)} />

            <div className="container">
                <p data-aos="zoom-in" className="howToAvtoText">ВИДЕО-УРОКИ ПО ПРОГРАММЕ 1С: БУХГАЛТЕРИЯ 8 ДЛЯ УЗБЕКИСТАНА, РЕДАКЦИЯ 3.0</p>
            </div>
            <hr />
            <p data-aos="zoom-in" style={{fontSize:"25px"}} className="howToAvtoText">1. ВВОД НАЧАЛЬНЫХ ОСТАТКОВ</p>
            <div className="container mt-5">
                <div className="col-10">
                    {
                        video?.map((item, index) => (

                            <div className=" d-flex">
                                <React.Fragment key={index}>
                                    <p  onClick={() => selectVideo(item.name)}style={{ fontSize: "20px", color: "black", margin: "0 10px 0 0 " }}>{item.number} </p> <p onClick={() => selectVideo(item.name)} style={{ fontSize: "20px", color: "rgba(22, 47, 92, 0.836)", cursor:"pointer" }}>{item.name}</p>
                                </React.Fragment>
                            </div>
                        ))
                    }
                </div>
            </div>
            <p data-aos="zoom-in" style={{fontSize:"25px"}} className="howToAvtoText">2. ОПЕРАЦИИ С БАНКОМ</p>
            <div className="container mt-5">
                <div className="col-10">
                    {
                        video2?.map((item, index) => (

                            <div className=" d-flex">
                                <React.Fragment key={index}>
                                    <p  onClick={() => selectVideo2(item.name)}style={{ fontSize: "20px", color: "black", margin: "0 10px 0 0 " }}>{item.number} </p> <p onClick={() => selectVideo2(item.name)} style={{ fontSize: "20px", color: "rgba(22, 47, 92, 0.836)", cursor:"pointer" }}>{item.name}</p>
                                </React.Fragment>
                            </div>
                        ))
                    }
                </div>
            </div>
            <p data-aos="zoom-in" style={{fontSize:"25px"}} className="howToAvtoText">3. ОПЕРАЦИИ ПО КАССЕ</p>
            <div className="container mt-5">
                <div className="col-10">
                    {
                        video3?.map((item, index) => (

                            <div className=" d-flex">
                                <React.Fragment key={index}>
                                    <p  onClick={() => selectVideo3(item.name)}style={{ fontSize: "20px", color: "black", margin: "0 10px 0 0 " }}>{item.number} </p> <p onClick={() => selectVideo3(item.name)} style={{ fontSize: "20px", color: "rgba(22, 47, 92, 0.836)", cursor:"pointer" }}>{item.name}</p>
                                </React.Fragment>
                            </div>
                        ))
                    }
                </div>
            </div>
            <p data-aos="zoom-in" style={{fontSize:"25px"}} className="howToAvtoText">4. РАСЧЕТЫ С ПОДОТЧЕТНЫМИ ЛИЦАМИ</p>
            <div className="container mt-5">
                <div className="col-10">
                    {
                        video4?.map((item, index) => (

                            <div className=" d-flex">
                                <React.Fragment key={index}>
                                    <p  onClick={() => selectVideo4(item.name)}style={{ fontSize: "20px", color: "black", margin: "0 10px 0 0 " }}>{item.number} </p> <p onClick={() => selectVideo4(item.name)} style={{ fontSize: "20px", color: "rgba(22, 47, 92, 0.836)", cursor:"pointer" }}>{item.name}</p>
                                </React.Fragment>
                            </div>
                        ))
                    }
                </div>
            </div>
            <p data-aos="zoom-in" style={{fontSize:"25px"}} className="howToAvtoText">5. ПОКУПКИ</p>
            <div className="container mt-5">
                <div className="col-10">
                    {
                        video5?.map((item, index) => (

                            <div className=" d-flex">
                                <React.Fragment key={index}>
                                    <p  onClick={() => selectVideo5(item.name)}style={{ fontSize: "20px", color: "black", margin: "0 10px 0 0 " }}>{item.number} </p> <p onClick={() => selectVideo5(item.name)} style={{ fontSize: "20px", color: "rgba(22, 47, 92, 0.836)", cursor:"pointer" }}>{item.name}</p>
                                </React.Fragment>
                            </div>
                        ))
                    }
                </div>
            </div>
            <div>
                <Contact/>
            </div>
        </div >
    )
}

export default ForVideo;