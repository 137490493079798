import React,{useEffect} from "react";
import { useTranslation } from "react-i18next";
import { ClockCircleOutlined, HeartOutlined, UserOutlined, CalendarOutlined } from "@ant-design/icons"
import Contact from "../contact/Contact";

const ITSSupport = () =>{

    const {t} = useTranslation()
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    return(
        <div>
            <div className="container mt-5 mb-4">
                <div className="row">
                    <p data-aos="zoom-in" className="howToAvtoText">{t('its')}</p>
                    <div className="col-12">
                        <div data-aos="zoom-in" className="text-center clitsenziya">
                            {t("itsTitle")}
                        </div>
                    </div>
                </div>
            <hr />
            </div>
            <div className="container-fluid mt-5">
                <div className="row">
                    <div className="col-md-6 col-sm-12 text-center mt-4 mb-5 inContainerFluidCol">
                        <div data-aos="zoom-in" className="howToAvtoTextChild"><ClockCircleOutlined /></div>
                        <div data-aos="zoom-in" className="titleStatistik">
                            <div className="inText">{t("itsCardTitle")}</div>
                        </div>
                        <div data-aos="zoom-in" className="childTex"><div className="childTextIn">{t("itsCardDesc")}</div></div>
                    </div>
                    <div className="col-md-6 col-sm-12 text-center mt-4 mb-5 inContainerFluidCol">
                        <div data-aos="zoom-in" className="howToAvtoTextChild"><HeartOutlined /></div>
                        <div data-aos="zoom-in" className="titleStatistik">
                            <div className="inText">{t("itsCardTitle2")}</div>
                        </div>
                        <div data-aos="zoom-in" className="childTex"><div className="childTextIn">{t("itsCardDesc2")}</div></div>
                    </div>
                    <div className="col-md-6 col-sm-12 text-center mt-4 mb-5 inContainerFluidCol">
                        <div data-aos="zoom-in" className="howToAvtoTextChild"><UserOutlined /></div>
                        <div data-aos="zoom-in" className="titleStatistik">
                            <div className="inText">{t("itsCardTitle3")}</div>
                        </div>
                        <div data-aos="zoom-in" className="childTex"><div className="childTextIn">{t("itsCardDesc3")}</div></div>
                    </div>
                    <div className="col-md-6 col-sm-12 text-center mt-4 mb-5 inContainerFluidCol">
                        <div data-aos="zoom-in" className="howToAvtoTextChild"><CalendarOutlined /></div>
                        <div data-aos="zoom-in" className="titleStatistik">
                            <div className="inText">{t("itsCardTitle4")}</div>
                        </div>
                        <div data-aos="zoom-in" className="childTex"><div className="childTextIn">{t("itsCardDesc4")}</div></div>
                    </div>
                </div>
            </div>

            <div>
                <Contact/>
            </div>
        </div>
    )
}

export default ITSSupport