import React,{useEffect} from "react";
import Contact from "../contact/Contact";
import { useTranslation } from "react-i18next";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import firmaBoshqaruvi1 from "../../assets/firmaBoshqaruvi1.png"
import firmaBoshqaruvi2 from "../../assets/firmaBoshqaruvi2.png"
import firmaBoshqaruvi3 from "../../assets/firmaBoshqaruvi3.png"
import firmaBoshqaruvi4 from "../../assets/firmaBoshqaruvi4.png"
import { FormOutlined, FileProtectOutlined, CodeOutlined, BookOutlined, VideoCameraOutlined, CheckOutlined, PhoneOutlined } from "@ant-design/icons"
import { Link } from "react-router-dom";

const SavdoniBoshqaruvAbout = () => {

    const { t } = useTranslation()

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    return (
        <div>

            <div className="container ">
                <p data-aos="zoom-in" className="howToAvtoText">{t("howToAvtoText2")}</p>
                <div className="row" style={{ justifyContent: "center" }}>
                    <div className="col-10 mt-5">
                        <Carousel>
                            <div>
                                <img className="img-fluid" src={firmaBoshqaruvi1} />
                            </div>
                            <div>
                                <img className="img-fluid" src={firmaBoshqaruvi2} />
                            </div>
                            <div>
                                <img className="img-fluid" src={firmaBoshqaruvi3} />
                            </div>
                            <div>
                                <img className="img-fluid" src={firmaBoshqaruvi4} />
                            </div>
                        </Carousel>
                    </div>
                </div>
            </div>

            <div className="container mt-5">
                <p style={{ fontSize: "27px" }} data-aos="zoom-in" className="howToAvtoText">{t('consultic8SecondTitle3')}</p>
                <div className="col-10">
                    <div className="mt-3 d-flex">
                        <p style={{ fontSize: "20px", color: "black", margin: "0 10px 0 0 " }}>- </p> <p style={{ fontSize: "20px", color: "black" }}>
                            автоматизировать все задачи оперативного, управленческого учета, анализа и планирования торговых операций;
                        </p>
                    </div>
                    <div className="mt-3 d-flex">
                        <p style={{ fontSize: "20px", color: "black", margin: "0 10px 0 0 " }}>- </p>  <p style={{ fontSize: "20px", color: "black" }}>
                            управлять взаимоотношениями с клиентами (CRM);
                        </p>
                    </div>
                    <div className="mt-3 d-flex">
                        <p style={{ fontSize: "20px", color: "black", margin: "0 10px 0 0 " }}>- </p>  <p style={{ fontSize: "20px", color: "black" }}>
                            управлять закупками и анализировать поставщиков;
                        </p>
                    </div>
                    <div className="mt-3 d-flex">
                        <p style={{ fontSize: "20px", color: "black", margin: "0 10px 0 0 " }}>- </p>  <p style={{ fontSize: "20px", color: "black" }}>
                            вести складской учет и контроль запасов;
                        </p>
                    </div>
                    <div className="mt-3 d-flex">
                        <p style={{ fontSize: "20px", color: "black", margin: "0 10px 0 0 " }}>- </p>  <p style={{ fontSize: "20px", color: "black" }}>
                            управлять оптовыми, розничными и комиссионными продажами;
                        </p>
                    </div>
                    <div className="mt-3 d-flex">
                        <p style={{ fontSize: "20px", color: "black", margin: "0 10px 0 0 " }}>- </p>  <p style={{ fontSize: "20px", color: "black" }}>
                            подключить любое торговое оборудование;
                        </p>
                    </div>
                    <div className="mt-3 d-flex">
                        <p style={{ fontSize: "20px", color: "black", margin: "0 10px 0 0 " }}>- </p>  <p style={{ fontSize: "20px", color: "black" }}>
                            эффективно управлять денежными средствами, вести учет кредитов, депозитов и займов;
                        </p>
                    </div>
                    <div className="mt-3 d-flex">
                        <p style={{ fontSize: "20px", color: "black", margin: "0 10px 0 0 " }}>- </p>  <p style={{ fontSize: "20px", color: "black" }}>
                            анализировать финансовые результаты работы и многое другое!
                        </p>
                    </div>
                </div>
            </div>

            <div style={{ padding: "0 50px" }} className="container mt-5">
                <p style={{ fontSize: "27px" }} data-aos="zoom-in" className="howToAvtoText mb-5">{t("howToAvtoText2")}</p>
                <div className="mt-3">
                    <CheckOutlined style={{ fontSize: "30px", margin: "0 10px 0 0", color: "rgba(22, 47, 92, 0.836)" }} />  <b style={{ fontSize: "25px", fontWeight: "bold", color: "rgba(22, 47, 92, 0.836)" }}>
                        Продажи
                    </b>
                    <p style={{ fontSize: "20px", color: "black", marginLeft: "40px" }}>
                        Программа автоматизирует всю цепочку продаж: от создания коммерческого предложения до выставления счета и оформления возврата.
                    </p>

                </div>
                <div className="mt-5">
                    <CheckOutlined style={{ fontSize: "30px", margin: "0 10px 0 0", color: "rgba(22, 47, 92, 0.836)" }} />
                    <b style={{ fontSize: "25px", fontWeight: "bold", color: "rgba(22, 47, 92, 0.836)" }}>Взаимоотношения с клиентами (CRM)  </b>
                    <p style={{ fontSize: "20px", color: "black", marginLeft: "40px" }}>
                        CRM собирает и хранит детальную информацию по каждому клиенту. Вы сможете: планировать и управлять сделками и продажами, оперативно обрабатывать претензии
                        клиентов, контролировать работу менеджеров по продажам, оценивать результаты маркетинговых акций и др.
                    </p>
                </div>
                <div className="mt-5">
                    <CheckOutlined style={{ fontSize: "30px", margin: "0 10px 0 0", color: "rgba(22, 47, 92, 0.836)" }} />
                    <b style={{ fontSize: "25px", fontWeight: "bold", color: "rgba(22, 47, 92, 0.836)" }}>Закупки </b>
                    <p style={{ fontSize: "20px", color: "black", marginLeft: "40px" }}>
                        Программа имеет широкие возможности для управления закупками: выбор поставщиков, мониторинг цен,
                        формирование заказов и контроль их исполнения, составление графиков поставок и платежей, возвраты и др.
                    </p>
                </div>
                <div className="mt-5">
                    <CheckOutlined style={{ fontSize: "30px", margin: "0 10px 0 0", color: "rgba(22, 47, 92, 0.836)" }} />
                    <b style={{ fontSize: "25px", fontWeight: "bold", color: "rgba(22, 47, 92, 0.836)" }}>Запасы </b>
                    <p style={{ fontSize: "20px", color: "black", marginLeft: "40px" }}>
                        Программа УТ обеспечивает полный контроль товарных запасов. Поддерживается детальный оперативный учет товаров на складах. В системе фиксируются
                        все складские операции: поступление и перемещение товаров, продажа, инвентаризация и др.
                    </p>
                </div>
                <div className="mt-5">
                    <CheckOutlined style={{ fontSize: "30px", margin: "0 10px 0 0", color: "rgba(22, 47, 92, 0.836)" }} />
                    <b style={{ fontSize: "25px", fontWeight: "bold", color: "rgba(22, 47, 92, 0.836)" }}>Финансы </b>
                    <p style={{ fontSize: "20px", color: "black", marginLeft: "40px" }}>
                        В УТ 11 для оценки финансового состояния компании разработан отчет «Управленческий баланс». С его помощью вы можете контролировать
                        использование финансовых средств, анализировать учетные данные, взаиморасчеты с клиентами и поставщиками и др.
                    </p>
                    <p></p>
                </div>
                <div className="mt-5">
                    <CheckOutlined style={{ fontSize: "30px", margin: "0 10px 0 0", color: "rgba(22, 47, 92, 0.836)" }} />
                    <b style={{ fontSize: "25px", fontWeight: "bold", color: "rgba(22, 47, 92, 0.836)" }}>Мониторинг и анализ показателей деятельности </b>
                    <p style={{ fontSize: "20px", color: "black", marginLeft: "40px" }}>
                        В программе создана система целевых показателей, которая позволяет анализировать работу компании, вовремя выявлять проблемные участки, контролировать выполнение
                        целей и задач, принимать взвешенные управленческие решения на основе точных данных.
                    </p>
                </div>
                <div className="mt-5">
                    <CheckOutlined style={{ fontSize: "30px", margin: "0 10px 0 0", color: "rgba(22, 47, 92, 0.836)" }} />
                    <b style={{ fontSize: "25px", fontWeight: "bold", color: "rgba(22, 47, 92, 0.836)" }}>Интеграция с «1С» </b>
                    <p style={{ fontSize: "20px", color: "black", marginLeft: "40px" }}>
                        Конфигурация интегрируется с другими решениями «1С». Например, «УТ 11» может быть использована
                        как управляющая система для программы «1С:Розница». Программа совместима с решениями «Бухгалтерия 8».
                    </p>
                </div>
            </div>

            <div className="container mb-4 mt-4">
                <p data-aos="zoom-in" className="howToAvtoText">БЕСПЛАТНО ПРИ ПОКУПКЕ</p>
                <div className="row mt-5" style={{ justifyContent: "center" }}>
                    <div data-aos="zoom-in" className="col-12 col-md-4 col-sm-6 col-lg-4 col-xl-4 col-xxl-4">
                        <div style={{ width: "100%" }} className="card forOurBizines">
                            <div className="mb-4" style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                                <FileProtectOutlined style={{ color: "rgba(22, 47, 92, 0.836)" }} />
                            </div>
                            <div className="card_titlerOur">
                                {t("freeBuy")}
                            </div>

                        </div>
                    </div>



                    <div data-aos="zoom-in" className="col-12 col-md-4 col-sm-6 col-lg-4 col-xl-4 col-xxl-4">
                        <div className="card forOurBizines">
                            <div className="mb-4" style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                                <BookOutlined style={{ color: "rgba(22, 47, 92, 0.836)" }} />
                            </div>
                            <div className="card_titlerOur">
                                {t("freeBuy5S")}
                            </div>

                        </div>
                    </div>

                </div>
            </div>

            <div>
                <Contact />
            </div>
        </div>
    )
}

export default SavdoniBoshqaruvAbout;