import React, { useState, useEffect } from "react";
import "./header.css"
import logo from "../../assets/logoMP.png"
import ReactFlagsSelect from "react-flags-select";
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar';
import { useTranslation } from "react-i18next";
import { Link, NavLink } from "react-router-dom";
import burger from "../../assets/menu.png"
import closeBurgerImg from "../../assets/closeBurger.svg"
import Aos from "aos";
import "aos/dist/aos.css"
import uzb from "../../assets/uzb.jpg"
import rusImg from "../../assets/rus.png"
import usa from "../../assets/usa.jpg"
import { Us, Uz, Ru } from "react-flags-select";



const Header = ({ changeLang }) => {

    const { t } = useTranslation()
    const [menu, setMenu] = useState(false)
    const [selected, setSelected] = useState("RU");
    const handlerChangeMenu = () => {
        setMenu(!menu)
        // console.log(menu);
    }
    // console.log(selected);
    const changeLangHandler = (e) => {
        changeLang(e)
        setSelected(e)
    }
    useEffect(() => {
        Aos.init();
    }, [])


    const [pathName, setPathName] = useState("/")

    let windowPathName = sessionStorage.getItem('pathname')


    const avtiveMenu = (name) => {
        setPathName(name)
    }
    // (e) => changeLangHandler(e.target.value)
    // /cources
    return (
        <>
            <Navbar data-aos="fade-down" className="navbarbg">
                {/* <Container> */}
                <Link to="/" onClick={() => {avtiveMenu("/"); sessionStorage.setItem('pathname','/')}} className="for_logo"><img src={logo} /></Link>
                <Nav className="me-auto">
                    <Link onClick={() => {avtiveMenu("/"); sessionStorage.setItem('pathname','/')}} to="/" className={windowPathName == "/" ? "activeText" : "menuText"}>{t("main")}</Link>
                    <Link onClick={() => {avtiveMenu("/about"); sessionStorage.setItem('pathname','/about')}} to="/about" className={windowPathName == "/about" ? "activeText" : "menuText"}>{t("about")}</Link>
                    <Link onClick={() => {avtiveMenu("/our-services"); sessionStorage.setItem('pathname','/our-services')}} to="/our-services" className={windowPathName == "/our-services" ? "activeText" : "menuText"}>{t("ourServices")}</Link>
                    <Link onClick={() => {avtiveMenu("/cources")}} to="#" className={windowPathName == "/cources" ? "activeText" : "menuText"}>{t("coursec")}</Link>
                    <Link onClick={() => {avtiveMenu("/contact"); sessionStorage.setItem('pathname','/contact')}} to="/contact" className={windowPathName == "/contact" ? "activeText" : "menuText"}>{t("contact")}</Link>
                </Nav>
                <ReactFlagsSelect
                    className="for-language"
                    selected={selected}
                    countries={["RU", "UZ", "US"]}
                    onSelect={(code) => changeLangHandler(code)}
                />
                {/* <select className="for-language" onChange={(e) => changeLangHandler(e.target.value)}>
                    <option value={"ru"}>
                        <div>
                            <img src={rusImg} alt="" />
                        </div>
                    </option>
                    <option value={"en"}><img src={usa} alt="USA flag" />en</option>
                    <option value={"uz"}><img src={uzb} alt="USA flag" />uz</option>
                </select> */}
                <div onClick={handlerChangeMenu} className="for-burger"><img src={burger} alt="Burger" /></div>
                {/* </Container> */}
            </Navbar>
            <div className="menu-box">
                <div className={menu ? "menu-box-child-active" : "menu-box-child"}>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <img onClick={handlerChangeMenu} className="closeBurgerImg" src={closeBurgerImg} alt="closeBurger" />
                        {/* <select className="for-language-active" onChange={(e) => changeLangHandler(e.target.value)}>
                            <option value={"ru"}><img />ru</option>
                            <option value={"en"}><img />en</option>
                            <option value={"uz"}><img />uz</option>
                        </select> */}
                        <ReactFlagsSelect
                            className="for-language-active"
                            selected={selected}
                            countries={["RU", "UZ", "US"]}
                            onSelect={(code) => changeLangHandler(code)}
                        />
                    </div>
                    <div className="me-auto-active">
                        <div><Link onClick={handlerChangeMenu} to="/" className="menuText">{t("main")}</Link></div>
                        <div><Link onClick={handlerChangeMenu} to="/about" className="menuText">{t("about")}</Link></div>
                        <div><Link onClick={handlerChangeMenu} to="/our-services" className="menuText">{t("ourServices")}</Link></div>
                        <div><Link onClick={handlerChangeMenu} to="/cources" className="menuText">{t("coursec")}</Link></div>
                        <div><Link onClick={handlerChangeMenu} to="/contact" className="menuText">{t("contact")}</Link></div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Header


// <Parallax
// blur={{ min: -13, max: 15 }}
// bgImage={img1}
// bgImageAlt="the dog"
// strength={-400}
// >
// Blur transition from min to max
// <div style={{ height: '200px' }} />
// </Parallax>