const translationEn = {
    main:"MAIN",
    about:"ABOUT",
    ourServices:"OUR SERVICES",
    coursec:"COURCES",
    contact:"CONTACT",
    firstAuto:"Automate your business in with us",
    firstWhenBiznes:"When your business needs to be automated?",
    inText1:"YOU ARE MISSING IMPORTANT INDICATORS",
    inText2:"FINANCIAL DISORDER DESTROYES ALL INCOME",
    inText3:"THE COMMUNITY IS BURIED IN REGULAR WORK",
    inText4:"YOUR BUSINESS IS IN DISORDER",
    inTextChild1:"Employees spend a lot of time preparing the reports you need at the same time!",
    inTextChild2:"Money is spent randomly and you are constantly filling cash gaps manually",
    inTextChild3:"Employees spend a lot of time on routine tasks, which prevents them from doing really important tasks.",
    inTextChild4:"Business processes in your company are not structured and optimized according to a strict algorithm.",
    howToHelp:"HOW CAN WE HELP YOU?",
    oneCAccountingText:"1C: ACCOUNTING 8",
    oneCAccountingText2:"The most popular accounting program in Uzbekistan. Fully automates accounting, tax accounting and reporting.",
    howToAvtoText:"1C: COMPANY MANAGEMENT",
    oneCAccountingText3:"Everything you need to automate your small and medium business in one program.",
    howToAvtoText2:"1C: TRADE MANAGEMENT 11",
    oneCAccountingText3:"A modern tool for increasing the efficiency of trading companies.",
    // CONTACT 
    contactTilt:"Contact us",
    // ABOUT
    companyTitle:"was founded by a group of like-minded people in 2023. MY PROGRAM helps companies of a wide range of industries and sectors to work with high profitability, constantly adapt to changes and adhere to the principles of sustainable development. Creates and implements new, affordable and effective solutions and opportunities for business and society.",
    Honesty:"Honesty",
    HonestyTitle:"We always strive to fulfill our obligations towards clients, partners and employees.",
    Professionalism:"Professionalism",
    ProfessionalismTitle:"Professionalism is a combination of competence and responsibility for us. Competence is experience and continuous professional development. Responsibility is the fulfillment of undertaken obligations in terms of timing and content.",
    cooperation:"Focus on long-term relationships",
    cooperationTitle:"When it is faced with a choice, the Company sacrifices short-term benefits in favor of strategic achievements. We are confident that clients, partners, and employees must be dealt with in such a way that the relationship lasts a long time and is beneficial for both parties. We invest profits in business development, creating the basis for work in the future!",
    value:"OUR VALUES, WHICH EVERY COMPANY EMPLOYEE IS ADHERED TO",
    CERTIFICATIONS:"OUR CERTIFICATES",
    ourServicesFirst:`"IT" SOLUTION FOR LARGE AND MEDIUM BUSINESS`,
    ourServicesFirstTitle:"WE OFFER ALL THE SERVICES YOU NEED TO AUTOMATE YOUR BUSINESS",
    clitsenziya:`This page contains a list of 1C licensed programs, where you can choose and purchase 1C programs from the official "1C" franchisor.`,
    roznitsa:"1С: RETAIL 8",
    roznitsaTitle:"A modern tool for increasing retail efficiency.",
    documentAbarot:"1C: DOCUMENT FLOW",
    documentAbarotTitle:`Full control of all documents. "1C: Document circulation" is a modern system for managing documents, business processes and employee interactions.`,
    // xizmatlar
    avtomatBiznes:"AUTOMATE YOUR BUSINESS",
    oneCProduct:"1C SOFTWARE PRODUCTS",
    oneCSanoat:"INDUSTRIAL SOLUTIONS FROM 1C",
    oneCWork:"LEARN TO WORK IN 1C",
    oneCITC:"1C (ITS) SUPPORT",
    oneCWeb:"WEBSITE CREATION",
    oneCDesign:"LOGO AND DESIGN CREATION",
    oneCBitriks:"IMPLEMENT BITRIKC 24",
    oneCLearnMore:"LEARN MORE",
    OvtomatBiznes:"ADVANTAGES OF BUSINESS AUTOMATION",
    howtoWorkWe:"HOW DO WE WORK?",
    // sanoat yechimlari
    sanoatYechimiTitle:"MY PROGRAM's industrial solutions are aimed at maximally matching the automation needs of the most important business processes for enterprises, allowing to reduce consumer costs thanks to what is delivered as ready-made solutions.",
    first:"PRODUCTION",
    second:"MECHANICAL ENGINEERING",
    shird:"PHARMACEUTICALS",
    four:"TEXTILE",
    five:"CONSTRUCTION",
    six:"INSURANCE",
    seven:"LEASING",
    eight:"LOGISTICS",
    nine:"EDUCATION",
    ten:"COMMON FOOD",
    // 1c bugalteriya mahsulotlari
    consultic8:"1C: ACCOUNTING 8 FOR UZBEKISTAN. ELECTRONIC DELIVERY",
    consultic8SecondTitle: "1C: ACCOUNTING FOR UZBEKISTAN 8. ELECTRONIC DELIVERY",
    accountingEmployees:"make the work of accounting employees easier - the program takes care of 99% of labor-intensive calculations and fully automates accounting",
    accountingEmployees2:"automate the generation of primary documentation;",
    accountingEmployees3:"generation of regulated reporting (tax, statistical, reporting to funds);",
    accountingEmployees4:"keep records of legal entities with different taxation systems;",
    accountingEmployees5:"eliminate errors in reporting thanks to data verification and auto-filling of fields in reporting;",
    accountingEmployees6:"вести учет в соответствии с требованиями законодательства;",
    accountingEmployees7:"анализировать деятельность компании и многое другое!",
    erpi:"1C: ERP ENTERPRISE MANAGEMENT 2",
    erpiDesc:"The flagship 1C solution for complex automation of multi-industry enterprises.",
     // ITS Support
     its:"INFORMATION AND TECHNOLOGICAL SUPPORT 1C",
     itsTitle:"1C: ITS (Information Technology Support) is comprehensive support for users of 1C programs. By concluding a 1C:ITS agreement, you will have access to consultations, web services and materials for comfortable and efficient work.",
     itsCardTitle:"SUPPORT WHEN WORKING IN 1C",
     itsCardTitle2:"INDIVIDUAL SUPPORT",
     itsCardTitle3:"QUALIFIED SPECIALISTS",
     itsCardTitle4:"1C:ITS SERVICE PACKAGE ",
     itsCardDesc:"Under the ITS agreement, users have access to websites with the latest versions of programs, 1C support services (depending on the tariff) and a consultation line, which will allow the user to receive daily assistance from MY PROGRAM specialists, around the clock.",
     itsCardDesc2:"For the convenience of our clients, we offer several options for 1C support. It is possible to select an individual version of 1C support for a specific enterprise, as well as implement highly specialized industry solutions.",
     itsCardDesc3:"All specialists have professional certificates from 1C, as well as experience in methodological and practical work with programs. Enjoy communicating with professionals",
     itsCardDesc4:"Preparation, verification and sending of reports to regulatory authorities, automatic completion of counterparty details, exchange of electronic invoices and other documents with counterparties, etc.",

    //  BIZNESNI RIVOJLANTIRISH

    CrdBiznes1:"Increase in working capital through comprehensive optimization of the company.",
    CrdBiznes2:"Reducing the number of errors due to the reduction of the human factor.",
    CrdBiznes3:"Increased productivity and operational efficiency.",
    CrdBiznes4:"Ensuring secure storage of information with restricted access.",
    CrdBiznes5:"Improving the quality of goods and services.",
    CrdBiznes6:"Regular and transparent monitoring.",

    //  BIZNESNI RIVOJLANTIRISH
 
}

export default translationEn;