import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./Bitrix.css"
import Contact from "../contact/Contact";
import BitrixMainImg from "../../assets/BitrixMain.png"

const BitrixAbout = () => {

    const { t } = useTranslation()
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div>
            <div className="container mt-5">
                <div className="row" style={{ padding: "0 7%" }}>
                    <div data-aos="fade-right"
                        data-aos-anchor="#example-anchor"
                        data-aos-offset="500"
                        data-aos-duration="500" className="col-12 col-md-6 col-sm-6 col-xl-6 col-xxl-6" style={{ display: "flex", alignItems: "center" }}>
                        <div>
                            <div style={{ display: "flex" }}>
                                <div className="bitrixTitle">БИТРИКС</div>
                                <div className="bitrixNumber">24</div>
                            </div>

                            <div className="BitrixDesc">
                                Объединяет компанию
                            </div>

                            <div className="BitrixButtonGroup mt-3">
                                <a href="https://auth2.bitrix24.net/" style={{ color: "white", textDecoration: "none" }} target="blank">
                                    <button style={{ fontSize: "14px" }} className="btn btn-primary">Начать бесплатно</button>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div data-aos="fade-left"
                        data-aos-anchor="#example-anchor"
                        data-aos-offset="500"
                        data-aos-duration="500" className="col-12 col-md-6 col-sm-6 col-xl-6 col-xxl-6">
                        <img className="img-fluid" src={BitrixMainImg} alt="Bitrix main img" />
                    </div>
                </div>
            </div>

            <p data-aos="zoom-in" className="howToAvtoText">ЧТО ТАКОЕ БИТРИКС24?</p>
            <div className="container mt-5 mb-5">
                <div className="row mt-5">
                    <div data-aos="zoom-in" className="col-12 col-md-12 col-sm-6 col-xl-6 col-xxl-6 mt-3">
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/WON4qYmW4MQ?si=JgLO3iIJIRF9_RqM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    </div>
                    <div data-aos="zoom-in" className="col-12 col-md-12 col-sm-6 col-xl-6 col-xxl-6" style={{ padding: "20px 0 0 0" }}>
                        <div className="bitrixTitle2">БИТРИКС24.CRM</div>
                        <div style={{ padding: "0" }} className="mt-3">помогает продавать больше:</div>
                        <div className="mt-3">
                            <ul style={{ padding: "0" }}>
                                <li>берет под контроль все каналы коммуникаций с клиентами;</li>
                                <li>подсказывает менеджеру, что делать;</li>
                                <li>автоматизирует продажи;</li>
                                <li>создаёт рассылки под разные сегменты;</li>
                                <li> сообщает руководителю о проблеме;</li>
                                <li> устанавливает взаимодействие с 1С.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container mt-5 mb-5">
                <div className="row mt-5">
                    <div data-aos="zoom-in" className="col-12 col-md-12 col-sm-6 col-xl-6 col-xxl-6" style={{ padding: "20px 0 0 0" }}>
                        <div className="bitrixTitle2">БИТРИКС24.КОНТАКТ-ЦЕНТР</div>
                        <div style={{ padding: "0" }} className="mt-3">объединяет в CRM каналы коммуникаций с клиентами:</div>
                        <div className="mt-3">
                            <ul style={{ padding: "0" }}>
                                <li>звонки по телефону;</li>
                                <li>письма на электронную почту;</li>
                                <li>чат на сайте;</li>
                                <li>сообщения на Facebook;</li>
                                <li>заказы в Instagram;</li>
                                <li>вопросы в ВКонтакте,</li>
                                <li>переписка Яндекс.Чат и другие.</li>
                            </ul>
                        </div>
                        <div style={{ padding: "0" }} className="mt-3">Вся информация сохраняется в карточках CRM</div>
                    </div>
                    <div data-aos="zoom-in" className="col-12 col-md-12 col-sm-6 col-xl-6 col-xxl-6 mt-3">
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/4X09G41i71U?si=bhp0tctmL3mifbhy" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    </div>

                </div>
            </div>
            <div className="container mt-5 mb-5">
                <div className="row mt-5">
                    <div data-aos="zoom-in" className="col-12 col-md-12 col-sm-6 col-xl-6 col-xxl-6 mt-3">
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/U-4N2ez4IbE?si=gi7MgKJ_-qOdKI7o" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    </div>
                    <div data-aos="zoom-in" className="col-12 col-md-12 col-sm-6 col-xl-6 col-xxl-6" style={{ padding: "20px 0 0 0" }}>
                        <div className="bitrixTitle2">БИТРИКС24.ЗАДАЧИ И ПРОЕКТЫ</div>
                        <div style={{ padding: "0" }} className="mt-3">помогают работать вместе и успевать вовремя.</div>
                        <div style={{ padding: "0" }}>Если задача поставлена в Битрикс24, она будет выполнена:</div>
                        <div className="mt-3">
                            <ul style={{ padding: "0" }}>
                                <li>все причастные наблюдают за её выполнением и вносят свои корректировки своевременно;</li>
                                <li>о задачах напоминают счётчики;</li>
                                <li>она будет выведена вверх списка согласно приоритетам и срочности;</li>
                                <li>о нарушении сроков тут же узнает постановщик;</li>
                                <li>невыполнение отразится на коэффициенте эффективности как ответственного,</li>
                                <li>так и соисполнителей.</li>
                            </ul>
                        </div>
                        <div style={{ padding: "0" }} className="mt-3">Вся информация сохраняется в карточках CRM</div>
                    </div>


                </div>
            </div>
            <div className="container mt-5 mb-5">
                <div className="row mt-5">

                    <div data-aos="zoom-in" className="col-12 col-md-12 col-sm-6 col-xl-6 col-xxl-6" style={{ padding: "20px 0 0 0" }}>
                        <div className="bitrixTitle2">БИТРИКС24.САЙТЫ И МАГАЗИНЫ</div>
                        <div style={{ padding: "0" }} className="mt-3">
                            Главная задача сайта и лендинга компании — не просто быть красивым, а приводить клиентов. Сайты в Битрикс24
                            создаются, чтобы продавать! Используйте все возможности сайта и как конструктора, и как части CRM:F
                        </div>
                        <div className="mt-3">
                            <ul style={{ padding: "0" }}>
                                <li>бесплатный онлайн-чат</li>
                                <li>графический редактор</li>
                                <li>многостраничный сайт</li>
                                <li> встроенные виджеты</li>
                                <li> анимация блоков</li>
                                <li>бесплатный хостинг и домен</li>
                                <li>SEO</li>
                            </ul>
                        </div>
                    </div>
                    <div data-aos="zoom-in" className="col-12 col-md-12 col-sm-6 col-xl-6 col-xxl-6 mt-3">
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/8_MzDktbznk?si=MwrKn9-6PP9_zRYX" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    </div>

                </div>
            </div>
            <div className="container mt-5 mb-5">
                <div className="row mt-5">
                    <div data-aos="zoom-in" className="col-12 col-md-12 col-sm-6 col-xl-6 col-xxl-6 mt-3">
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/6NMb3zldoTM?si=WX4Ig3BvxGJU8jK7" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    </div>
                    <div data-aos="zoom-in" className="col-12 col-md-12 col-sm-6 col-xl-6 col-xxl-6" style={{ padding: "20px 0 0 0" }}>
                        <div className="bitrixTitle2">БИТРИКС24.ОФИС</div>
                        <div style={{ padding: "0" }} className="mt-3">
                        помогает работать вместе:
                        </div>
                        <div className="mt-3">
                            <ul style={{ padding: "0" }}>
                                <li>открытые и закрытые чаты;</li>
                                <li>общий диск;</li>
                                <li>календарь для встреч с учётом планов участников;</li>
                                <li>создание групп по направлениям;</li>
                                <li> отчеты руководителю в определённое время;</li>
                                <li>бизнес-процессы и другие инструменты для совместной работы.</li>
                            </ul>
                        </div>
                    </div>


                </div>
            </div>
            <div>
                <Contact />
            </div>
        </div>
    )
}

export default BitrixAbout;