import React, {useEffect} from "react";
import Aos from "aos";
import "aos/dist/aos.css"
import product2 from "../../assets/product2.png"
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const KompaniyaBoshqaruvi = () =>{

    const { t } = useTranslation()
    const navigate = useNavigate()
    
    useEffect(() => {
        Aos.init();
    }, [])

    return(
        <div>
             <div className="row forBacground2 mt-5 mb-5 ">
                    <div className="col-12">
                    <div className="ONEcBugalter">
                            <div><img data-aos="zoom-in" className="img-fluid forImageBlur" src={product2} alt="Product 1"/></div>
                            <div data-aos="zoom-in" className="ONEcBugalterIN">
                                <div><p className="oneCAccountingText">{t("howToAvtoText")}</p>
                                <p className="oneCAccountingText2">{t("oneCAccountingText3")}</p>
                                <button onClick={()=>navigate('/our-services/firm management')} className="btn btn-primary">{t("oneCLearnMore")}</button></div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="col-md-6 col-sm-12">
                        
                        </div> */}
                </div>
        </div>
    )
}

export default KompaniyaBoshqaruvi;