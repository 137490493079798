import React from 'react';
import { Parallax } from 'react-parallax';
import Header from './components/header/Header';
import i18next from 'i18next';
import { initReactI18next, useTranslation } from 'react-i18next';
import translationRu from './locale/tnaslationsRu';
import translationUz from './locale/translationsUz';
import translationEn from './locale/translationsEn';
import { Route, Routes } from 'react-router-dom';
import Main from './pages/main/Main';
import About from './pages/about/About';
import OurServices from './pages/OurServices/OurServices';
import Cources from './pages/cources/Cources';
import Contact from './pages/contact/Contact';
import Footer from './components/header/Footer/Footer';
import About1CMahsulotlari from './pages/About1CMahsulotlari/About1CMahsulotlar';
import AboutBiznesAvtomatlash from './pages/AboutBiznesAvtomatlash/AboutBiznesAvtomatlash';
import AboutSanoatYechimlari from './pages/AboutSanoatYechimlari/AboutSanoatYechimlari';
import About1CMahsulotlariIn8 from './pages/About1CMahsulotlari/About1CMahsulotlariIn8';
import ForVideo from './pages/About1CMahsulotlari/ForVideo';
import FirmaBoshqaruviAbout from './pages/FirmaBoshqaruviAbout/FirmaBoshqaruviAbout';
import SavdoniBoshqaruvAbout from './pages/SavdoniBoshqaruvAbout/SavdoniBoshqaruvAbout';
import RoznitsaAbout from './pages/RoznitsaAbout/RoznitsaAbout';
import DocumentAbarotAbout from './pages/DocumentAbarotAbout/DocumentAbarotAbout';
import ITSSupport from './pages/ITSSuppurt/ITSSupport';
import BitrixAbout from './pages/BitrixAbout/BitrixAbout';
import { Helmet } from 'react-helmet';


i18next.use(initReactI18next).init({
  resources: {
    RU: { translation: translationRu },
    UZ: { translation: translationUz },
    US: { translation: translationEn }
  },
  lng: "RU",
  fallbackLng: "RU"
})

function App() {


  const changeLang = (val) => {
    i18next.changeLanguage(val)
  }
  // const parallaxData = [
  //   {
  //     start: 0,
  //     end: 500,
  //     properties: [
  //       {
  //         startValue: 1,
  //         endValue: 2,
  //         property: "scale",
  //       },
  //     ],
  //   },
  // ];

  return (
    <div style={{ width: "100%", position: "relative" }}>
      <Header changeLang={changeLang} />
      <Helmet>
        <title> 1c бўйича манфаатли йечимлар айнан шу йерда | MY PROGR@M</title>
        <meta name="description" content="Узбекистон буйича 1с программасини урнатамиз ва сифатли  хизмат корсатамиз. Корхонангизни биз билан автоматлаштиринг - MY PROGR@M - сизнинг хизматинггизга дойим тайёр" />
      </Helmet>
      <Routes>
        <Route path='/' element={<Main />} />
        <Route path='/about' element={<About />} />
        <Route path='/our-services' element={<OurServices />} />
        <Route path='/our-services/1 c programs' element={<About1CMahsulotlari />} />
        <Route path='/our-services/ biznes' element={<AboutBiznesAvtomatlash />} />
        <Route path='/our-services/ industry' element={<AboutSanoatYechimlari />} />
        <Route path='/our-services/1 cbukhgalteriya8' element={<About1CMahsulotlariIn8 />} />
        <Route path='/our-services/firm management' element={<FirmaBoshqaruviAbout />} />
        <Route path='/our-services/trade management' element={<SavdoniBoshqaruvAbout />} />
        <Route path='/our-services/roznitsa/about' element={<RoznitsaAbout />} />
        <Route path='/our-services/document/about' element={<DocumentAbarotAbout />} />
        <Route path='/our-services/1 video' element={<ForVideo />} />
        <Route path='/our-services/its' element={<ITSSupport />} />
        <Route path='/our-services/bitrix' element={<BitrixAbout />} />
        <Route path='/cources' element={<Cources />} />
        <Route path='/contact' element={<Contact />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
