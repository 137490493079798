import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./AboutSanoatYechimlari.css"
import FirstSanoat from "../../assets/firstSanoat.jpg"
import SecondSanoat from "../../assets/sanoatSecond.jpg"
import ThirdSanoat from "../../assets/thirdSanoat.jpeg"
import FourthSanoat from "../../assets/fourthSanoat.jpg"
import FivesSanoat from "../../assets/fivesSanoat.jpg"
import SixTHSanoat from "../../assets/sixthSanoat.jpg"
import SeventhSanoat from "../../assets/seventhSanoatjpg.jpg"
import EightSanoat from "../../assets/eightTHSanoatjpg.jpg"
import NightSanoat from "../../assets/nightTHSanoat.jpg"
import TenSanoat from "../../assets/tenThSanoat.jpg"
import Contact from "../contact/Contact";


const AboutSanoatYechimlari = () => {

    const { t } = useTranslation()
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    return (
        <div>
            <div className="container">
                <p data-aos="zoom-in" className="howToAvtoText">{t('oneCSanoat')}</p>
                <hr />
                <div className="col-12">
                    <div data-aos="zoom-in" className="text-center clitsenziya">
                        {t("sanoatYechimiTitle")}
                    </div>
                </div>
            </div>

            <div className="container mt-4">
                <div className="row" style={{display:"flex", justifyContent:"space-around"}}>
                    <div data-aos="zoom-in" className="col-12 col-md-5 col-sm-5 col-xl-5 col-xxl-5 mt-3 mb-3 forHover">
                        <div className="forHoverDiv">{t('first')}</div>
                    </div>
                    <div data-aos="zoom-in" className="col-12 col-md-5 col-sm-5 col-xl-5 col-xxl-5 mt-3 mb-3 forHover2">
                        <div className="forHoverDiv">{t('second')}</div>
                    </div>
                    <div data-aos="zoom-in" className="col-12 col-md-5 col-sm-5 col-xl-5 col-xxl-5 mt-3 mb-3 forHover3">
                        <div className="forHoverDiv">{t('shird')}</div>
                    </div>
                    <div data-aos="zoom-in" className="col-12 col-md-5 col-sm-5 col-xl-5 col-xxl-5 mt-3 mb-3 forHover4">
                        <div className="forHoverDiv">{t('four')}</div>
                    </div>
                    <div data-aos="zoom-in" className="col-12 col-md-5 col-sm-5 col-xl-5 col-xxl-5 mt-3 mb-3 forHover5">
                        <div className="forHoverDiv">{t('five')}</div>
                    </div>
                    <div data-aos="zoom-in" className="col-12 col-md-5 col-sm-5 col-xl-5 col-xxl-5 mt-3 mb-3 forHover6">
                        <div className="forHoverDiv">{t('six')}</div>
                    </div>
                    <div data-aos="zoom-in" className="col-12 col-md-5 col-sm-5 col-xl-5 col-xxl-5 mt-3 mb-3 forHover7">
                        <div className="forHoverDiv">{t('seven')}</div>
                    </div>
                    <div data-aos="zoom-in" className="col-12 col-md-5 col-sm-5 col-xl-5 col-xxl-5 mt-3 mb-3 forHover8">
                        <div className="forHoverDiv">{t('eight')}</div>
                    </div>
                    <div data-aos="zoom-in" className="col-12 col-md-5 col-sm-5 col-xl-5 col-xxl-5 mt-3 mb-3 forHover9">
                        <div className="forHoverDiv">{t('nine')}</div>
                    </div>
                    <div data-aos="zoom-in" className="col-12 col-md-5 col-sm-5 col-xl-5 col-xxl-5 mt-3 mb-3 forHover10">
                        <div className="forHoverDiv">{t('ten')}</div>
                    </div>
                </div>
            </div>

            <div>
                <Contact/>
            </div>
        </div>
    )
}

export default AboutSanoatYechimlari