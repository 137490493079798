const translationUz = {
    main: "BOSH SAHIFA",
    about: "KOMPANIYA HAQIDA",
    ourServices: "XIZMATLAR",
    coursec: "KURSLAR",
    contact: "ALOQA UCHUN",
    firstAuto: "Biz bilan biznesingizni avtomatlashtiring",
    firstWhenBiznes: "BIZNESINGIZNI QACHON AVTOMATLASHTIRISH KERAK?",
    inText1: "SIZ MUHIM KO`RSATKICHLARNI O`TKAZIB YUBORYABSIZ",
    inText2: "MOLIYAVIY TARTIBSIZLIK BARCHA DAROMADLARNI YO`Q QILADI",
    inText3: "JAMOA MUNTAZAM ISHLARGA KO`MILGAN",
    inText4: "SIZNING BIZNESINGIZ TARTIBSIZLIKDA",
    inTextChild1: "Xodimlar Sizga ayni vaqtda zarur bo'lgan hisobotlarni tayyorlashga ko'p vaqt sarflashadi!",
    inTextChild2: "Pul tasodifiy sarflanadi va Siz doimiy ravishda naqd pul bo'shliqlarini qo’lda to'ldirasiz",
    inTextChild3: "Xodimlar odatdagi vazifalarga ko`p vaqt sarflashadi, bu esa ularni haqiqatan ham muhim vazifalarni bajarishga to'sqinlik qiladi.",
    inTextChild4: "Sizning kompaniyangizdagi biznes jarayonlari qat'iy algoritmga muvofiq tuzilmagan va optimallashtirilmagan.",
    howToHelp: "SIZGA QANDAY YORDAM BERA OLAMIZ?",
    oneCAccountingText: "1C: BUXGALTERIYA 8",
    oneCAccountingText2: "O'zbekistondagi eng mashhur buxgalteriya dasturi. Buxgalteriya hisobi, soliq hisobi va hisobotni to'liq avtomatlashtiradi.",
    howToAvtoText: "1C: KOMPANIYA BOSHQARUVI",
    oneCAccountingText3: "Kichik va o'rta biznesni bitta dasturda avtomatlashtirish uchun kerak bo'lgan hamma narsa.",
    howToAvtoText2: "1C: SAVDONI BOSHQARISH 11",
    oneCAccountingText3: "Savdo kompaniyalari samaradorligini oshirish uchun zamonaviy vosita.",
    // CONTACT
    contactTilt: "Biz bilan bog'lanish",
    // ABOUT
    companyTitle: "2023 yilda bir guruh fikrlovchilar tomonidan tashkil etilgan. MY PROGRAM keng ko'lamli soha va tarmoqlar kompaniyalariga yuqori rentabellik bilan ishlashga, o'zgarishlarga doimiy moslashishga va barqaror rivojlanish tamoyillariga rioya qilishga yordam beradi. Biznes va jamiyat uchun yangi, arzon va samarali yechimlar va imkoniyatlar yaratadi va amalga oshiradi.",
    Honesty: "Halollik",
    HonestyTitle: "Biz har doim mijozlar, hamkorlar va xodimlarga nisbatan o'z majburiyatlarimizni bajarishga intilamiz!",
    Professionalism: "Professionallik",
    ProfessionalismTitle: "Biz uchun professionallik - bu kompetentsiya va mas'uliyat kombinatsiyasi. Kompetentsiya - bu tajriba va doimiy malaka oshirish. Mas'uliyat - bu vaqt va mazmun bo'yicha majburiyatlarni bajarish.",
    cooperation: "Uzoq muddatli hamkorlik",
    cooperationTitle: "Agar tanlov zarur bo'lsa, kompaniya strategik yutuqlari bir lahzalik foydadan afzalroq. Ishonchimiz komilki, mijozlar, hamkorlar, xodimlar bilan munosabatlar uzoq vaqt davom etadigan va har ikki tomon uchun ham foydali bo'ladigan tarzda harakat qilish kerak. Biz kelajakda ishlash uchun asos yaratib, biznes foydasini rivojlantirishga yo‘naltiramiz!",
    value: "KOMPANIYANING HAR BIR XODIMI RIOYA QILADIGAN QADRIYATLARIMIZ",
    CERTIFICATIONS: "SERTIFIKATLARIMIZ",
    ourServicesFirst: `KATTA VA O'RTA BIZNES UCHUN "IT" YECHIM`,
    ourServicesFirstTitle: "BIZ SIZGA BIZNESINGIZNI AVTOMATLASHTIRISH UCHUN KERAK BO'LGAN HAMMA XIZMATLARNI TAKLIF QILAMIZ",
    clitsenziya: "Ushbu sahifada 1C litsenziyalangan dasturlarning ro'yxati keltirilgan, bu yerda siz 1C dasturini “1C” rasmiy franchayzeridan tanlashingiz va sotib olishingiz mumkin.",
    roznitsa: "1C: CHAKANA SAVDO 8",
    roznitsaTitle: "Chakana savdo samaradorligini oshirish uchun zamonaviy vosita.",
    documentAbarot: "1C: HUJJAT AYLANISHI",
    documentAbarotTitle: `Barcha hujjatlarni to'liq nazorat qilish. "1C:Hujjat aylanishi" -bu hujjatlar, biznes jarayonlari va xodimlarning o'zaro munosabatlarini boshqarish uchun zamonaviy tizim.`,
    // xizmatlar
    avtomatBiznes: "BIZNESIZNGIZNI AVTOMATLASHTIRISH",
    oneCProduct: "1C DASTURIY MAHSULOTLARI",
    oneCSanoat: "1C DAN SANOAT YECHIMLARI",
    oneCWork: "1C DA ISHLASHNI O'RGANISH",
    oneCITC: "1C (ИТС) QO’LLAB-QUVVATLASH",
    oneCWeb: "VEB SAYT YARATISH",
    oneCDesign: "LOGOTIP HAMDA DIZAYN YARATISH",
    oneCBitriks: "БИТРИКС24-NI TATBIQ ETISH",
    oneCLearnMore: "KO'PROQ BILISH",
    OvtomatBiznes: "BIZNESNI AVTOMATLASHTIRISHNING AFZALLIKLARI",
    howtoWorkWe: "BIZ QANDAY ISHLAYMIZ?",
    // sanoat yechimlari
    sanoatYechimiTitle: "MY PROGRAM-ning sanoat yechimlari korxonalar uchun eng muhim biznes-jarayonlarni avtomatlashtirish ehtiyojlariga maksimal darajada mos kelishga qaratilgan bo'lib, ular tayyor yechimlar sifatida yetkazib beriladigan narsalar tufayli iste'molchilar xarajatlarini kamaytirishga imkon beradi.",
    first: "ISHLAB CHIQARISH",
    second: "MASHINASOZLIK",
    shird: "FARMATSEVTIKA",
    four: "TO’QIMACHILIK",
    five: "QURILISH",
    six: "SUG’URTA",
    seven: "LIZING",
    eight: "SLOGISTIKA",
    nine: "TA'LIM",
    ten: "UMUMIY OVQATLANISH",
    // 1c bugalteriya mahsulotlari
    consultic8: "1C: O'ZBEKISTON BO'YICHA BUXG'ALTERIYA 8. ELEKTRON YETKAZISH",
    consultic8SecondTitle: "1C: O'ZBEKISTON BO'YICHA BUXG'ALTERIYA 8. ELEKTRON YETKAZISH",
    accountingEmployees:"buxgalteriya xodimlarining ishini osonlashtirish - dastur ko'p mehnat talab qiladigan hisob-kitoblarning 99% ga g'amxo'rlik qiladi va buxgalteriya hisobini to'liq avtomatlashtiradi",
    accountingEmployees2:"birlamchi hujjatlarni yaratishni avtomatlashtirish;",
    accountingEmployees3:"tartibga solinadigan hisobotlarni shakllantirish (soliq, statistik, fondlarga hisobot);",
    accountingEmployees4:"turli soliq tizimlariga ega bo'lgan yuridik shaxslarning hisobini yuritish;",
    accountingEmployees5:"ma'lumotlarni tekshirish va hisobotdagi maydonlarni avtomatik to'ldirish tufayli hisobot berishdagi xatolarni bartaraf etish;",
    accountingEmployees6:"qonun hujjatlariga muvofiq hujjatlarni yuritish;",
    accountingEmployees7:"анализировать деятельность компании и многое другое!",
    erpi:"1C: ERP KORXONALARNI BOSHQARISH 2",
    erpiDesc:"Ko'p tarmoqli korxonalarni kompleks avtomatlashtirish uchun flagman 1C yechimi.",
     // ITS Support
     its:"AXBOROT VA TEXNOLOGIK TA’MINOT 1C",
     itsTitle:"1C: ITS (Axborot texnologiyalarini qo'llab-quvvatlash) 1C dasturlari foydalanuvchilari uchun har tomonlama yordamdir. 1C:ITS shartnomasini tuzish orqali siz qulay va samarali ishlash uchun maslahatlar, veb-xizmatlar va materiallardan foydalanish imkoniyatiga ega bo'lasiz.",
     itsCardTitle:"1C DA ISHLASHDA QO'LLAB-QUVVATLASH",
    itsCardTitle2:"INDIVIDUAL YORDAM",
    itsCardTitle3:"MALAKALI MUTAXSIZLAR",
    itsCardTitle4:"1C: UNING XIZMAT PAKETI ",
    itsCardDesc:"ITS shartnomasiga ko'ra, foydalanuvchilar dasturlarning eng so'nggi versiyalari, 1C qo'llab-quvvatlash xizmatlari (tarifga qarab) va maslahat liniyasiga ega veb-saytlarga kirish huquqiga ega, bu foydalanuvchiga MY PROGRAM mutaxassislaridan kechayu kunduz yordam olish imkonini beradi.",
    itsCardDesc2:"Mijozlarimizga qulaylik yaratish uchun biz 1C qo'llab-quvvatlash uchun bir nechta variantni taklif qilamiz. Muayyan korxona uchun 1C qo'llab-quvvatlashning individual versiyasini tanlash, shuningdek, yuqori darajada ixtisoslashgan sanoat echimlarini amalga oshirish mumkin.",
    itsCardDesc3:"Barcha mutaxassislar 1C dan professional sertifikatlarga ega, shuningdek, dasturlar bilan uslubiy va amaliy ish tajribasiga ega. Professionallar bilan muloqot qilishdan zavqlaning",
    itsCardDesc4:"Hisobotlarni tayyorlash, tekshirish va nazorat qiluvchi organlarga yuborish, kontragent ma'lumotlarini avtomatik ravishda to'ldirish, kontragentlar bilan elektron hisob-fakturalar va boshqa hujjatlarni almashish va boshqalar.",
    // BIZNES RIVOJLANTIRISH

    CrdBiznes1:"Kompaniyani har tomonlama optimallashtirish orqali aylanma mablag'larning o'sishi.",
    CrdBiznes2:"Ish samaradorligini o'sishi.",
    CrdBiznes3:"Tovarlar va xizmatlar sifatini oshirish.",
    CrdBiznes4:"Inson omilining pasayishi tufayli xatolar sonining kamayishi.",
    CrdBiznes5:"Kirish cheklangan holda ma'lumotlarning xavfsiz saqlanishini ta'minishi.",
    CrdBiznes6:"Muntazam va shaffof monitoring.",

    // BIZNES RIVOJLANTIRISH

}

export default translationUz;