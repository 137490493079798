import React, {useEffect} from "react";
import { useTranslation } from "react-i18next";
import Contact from "../contact/Contact";
import "./AboutBiznesAvtomatlash.css"
import { CheckOutlined } from "@ant-design/icons"

const AboutBiznesAvtomatlash = () => {

    const { t } = useTranslation()

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    return (
        <div>
            <div className="container-fluid">
            <div className="row">
                    <div data-aos="zoom-in" className="col-12  firstMainText">
                        <div data-aos="zoom-in" className="in_firstEffect">{t("firstAuto")}</div>
                    </div>
                </div>
            </div >
            <p data-aos="zoom-in" className="howToAvtoText mb-3 mt-5">{t("OvtomatBiznes")}</p>
            <div className="container">
                <div className="row" style={{ display: "flex", justifyContent: "space-between" }}>
                    <div className="col-12 col-md-6 col-sm-6 col-xl-6 col-xxl-6 biznesAvtomat">

                        <div className="card">
                            <p className="text-center textNumber">1</p>
                            {t('CrdBiznes1')}
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-sm-6 col-xl-6 col-xxl-6 biznesAvtomat">
                        <div className="card">
                            <p className="text-center textNumber">2</p>
                            {t('CrdBiznes2')}
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-sm-6 col-xl-6 col-xxl-6 biznesAvtomat">
                        <div className="card">
                            <p className="text-center textNumber">3</p>
                            {t('CrdBiznes3')}
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-sm-6 col-xl-6 col-xxl-6 biznesAvtomat">
                        <div className="card">
                            <p className="text-center textNumber">4</p>
                            {t('CrdBiznes4')}
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-sm-6 col-xl-6 col-xxl-6 biznesAvtomat">
                        <div className="card">
                            <p className="text-center textNumber">5</p>
                            {t('CrdBiznes5')}
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-sm-6 col-xl-6 col-xxl-6 biznesAvtomat">
                        <div className="card">
                            <p className="text-center textNumber">6</p>
                            {t('CrdBiznes6')}
                        </div>
                    </div>
                </div>
            </div>
            <p data-aos="zoom-in" className="howToAvtoText mb-3 mt-5">{t("howtoWorkWe")}</p>
            <div className="container mt-5 for_i">
                <div className="mt-3">
                    <CheckOutlined className="for_icon_biznes" />  <i>Biz sizning biznes jarayonlaringizni tahlil qilamiz va o'sish nuqtalarini topamiz</i>
                </div>
                <div className="mt-3">
                    <CheckOutlined className="for_icon_biznes" />  <i>Sizning vazifangiz uchun zamonaviy dasturiy yechimni tanlaymiz</i>
                </div>
                <div className="mt-3">
                    <CheckOutlined className="for_icon_biznes" />  <i>Dasturni sizning ehtiyojlaringizga moslashtiramiz</i>
                </div>
                <div className="mt-3">
                    <CheckOutlined className="for_icon_biznes" />  <i>Mavjud imkoniyatlarni biznes jarayoningizga moslashtiramiz</i>
                </div>
                <div className="mt-3">
                    <CheckOutlined className="for_icon_biznes" />  <i>Dasturda yozuvlarni qanday qilib to'g'ri saqlashni o'rgatamiz</i>
                </div>
                <div className="mt-3">
                    <CheckOutlined className="for_icon_biznes" />  <i>Samarali ishlash uchun texnik yordamni tashkil qilamiz</i>
                </div>
            </div>
            <div>
                <Contact />
            </div>
        </div>
    )
}
export default AboutBiznesAvtomatlash;