import React from "react";
import documentAbarot from "../../assets/DocumentAbarotFi.png"
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const DocumentAbarot = () =>{

    const { t } = useTranslation()
    const navigate = useNavigate()

    return(
        <div>
            <div className="row forBacground6 mt-5 mb-5 ">
                    <div className="col-12">
                        <div className="ONEcBugalter">
                            <div><img data-aos="zoom-in" className="img-fluid forImageBlur" src={documentAbarot} alt="Product 1"/></div>
                            <div data-aos="zoom-in" className="ONEcBugalterIN">
                                <div><p className="oneCAccountingText">{t("documentAbarot")}</p>
                                <p className="oneCAccountingText2">{t("documentAbarotTitle")}</p>
                                <button onClick={()=>navigate('/our-services/document/about')} className="btn btn-primary">{t("oneCLearnMore")}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="col-md-6 col-sm-12">
                        
                        </div> */}
                </div>
        </div>
    )
}

export default DocumentAbarot;