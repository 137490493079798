import React from "react";
import product1 from "../../assets/product1.png"
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const CBugalteriya = () => {

    const { t } = useTranslation()
    const navigate = useNavigate()

    return (
        <div>
            <div className="row forBacground mt-5 mb-5 ">
                <div className="col-12">
                    <div className="ONEcBugalter">
                        <div data-aos="zoom-in" className="ONEcBugalterIN">
                            <div><p className="oneCAccountingText">{t("oneCAccountingText")}</p>
                                <p className="oneCAccountingText2">{t("oneCAccountingText2")}
                                </p>
                                <button onClick={()=>navigate('/our-services/1 cbukhgalteriya8')} className="btn btn-primary">{t("oneCLearnMore")}</button>
                            </div>

                        </div>
                        <div><img data-aos="zoom-in" className="img-fluid forImageBlur" src={product1} alt="Product 1" /></div>

                    </div>

                </div>
                {/* <div className="col-md-6 col-sm-12">
                        
                        </div> */}
            </div>
        </div>
    )
}

export default CBugalteriya;