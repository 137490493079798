import React from "react";
import erpi from "../../assets/erpi.png"
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const Erpi = () => {

    const { t } = useTranslation()
    const navigate = useNavigate()

    return (
        <div>
            <div className="row forBacground mt-5 mb-5 ">
                <div className="col-12">
                    <div className="ONEcBugalter">
                        <div data-aos="zoom-in" className="ONEcBugalterIN">
                            <div><p className="oneCAccountingText">{t("erpi")}</p>
                                <p className="oneCAccountingText2">{t("erpiDesc")}
                                </p>
                                <button  className="btn btn-primary">{t("oneCLearnMore")}</button>
                            </div>

                        </div>
                        <div><img data-aos="zoom-in" className="img-fluid forImageBlur" src={erpi} alt="Product 1" /></div>

                    </div>

                </div>
                {/* <div className="col-md-6 col-sm-12">
                        
                        </div> */}
            </div>
        </div>
    )
}

export default Erpi;