import React,{useEffect} from "react";
import Contact from "../contact/Contact";
import { useTranslation } from "react-i18next";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import firstBugalteriyaAbout from "../../assets/firstBugalteriyaAbout.png"
import secondBugalteriyaAbout from "../../assets/secondBugalteriyaAbout.png"
import thirdBugalteriyaAbout from "../../assets/thirdBugalteriyaAbout.png"
import { FormOutlined, FileProtectOutlined, CodeOutlined, BookOutlined, VideoCameraOutlined, CheckOutlined, PhoneOutlined } from "@ant-design/icons"
import { Link } from "react-router-dom";

const About1CMahsulotlariIn8 = () => {

    const { t } = useTranslation()
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    return (
        <div>

            <div className="container ">
                <p data-aos="zoom-in" className="howToAvtoText">{t('consultic8')}</p>
                <div className="row" style={{ justifyContent: "center" }}>
                    <div className="col-10 mt-5">
                        <Carousel>
                            <div>
                                <img className="img-fluid" src={firstBugalteriyaAbout} />
                            </div>
                            <div>
                                <img className="img-fluid" src={secondBugalteriyaAbout} />
                            </div>
                            <div>
                                <img className="img-fluid" src={thirdBugalteriyaAbout} />
                            </div>
                        </Carousel>
                    </div>
                </div>
            </div>

            <div className="container mt-5">
                <p style={{ fontSize: "27px" }} data-aos="zoom-in" className="howToAvtoText mb-5">{t('consultic8SecondTitle')}</p>
                <div className="col-10">
                    <div className="mt-3 d-flex">
                        <p style={{ fontSize: "20px", color: "black", margin: "0 10px 0 0 " }}>- </p> <p style={{ fontSize: "20px", color: "black" }}>{t('accountingEmployees')}</p>
                    </div>
                    <div className="mt-3 d-flex">
                        <p style={{ fontSize: "20px", color: "black", margin: "0 10px 0 0 " }}>- </p>  <p style={{ fontSize: "20px", color: "black" }}>{t('accountingEmployees2')}</p>
                    </div>
                    <div className="mt-3 d-flex">
                        <p style={{ fontSize: "20px", color: "black", margin: "0 10px 0 0 " }}>- </p>  <p style={{ fontSize: "20px", color: "black" }}>{t('accountingEmployees3')}</p>
                    </div>
                    <div className="mt-3 d-flex">
                        <p style={{ fontSize: "20px", color: "black", margin: "0 10px 0 0 " }}>- </p>  <p style={{ fontSize: "20px", color: "black" }}>{t('accountingEmployees4')}</p>
                    </div>
                    <div className="mt-3 d-flex">
                        <p style={{ fontSize: "20px", color: "black", margin: "0 10px 0 0 " }}>- </p>  <p style={{ fontSize: "20px", color: "black" }}>{t('accountingEmployees5')}</p>
                    </div>
                    <div className="mt-3 d-flex">
                        <p style={{ fontSize: "20px", color: "black", margin: "0 10px 0 0 " }}>- </p>  <p style={{ fontSize: "20px", color: "black" }}>{t('accountingEmployees6')}</p>
                    </div>
                    <div className="mt-3 d-flex">
                        <p style={{ fontSize: "20px", color: "black", margin: "0 10px 0 0 " }}>- </p>  <p style={{ fontSize: "20px", color: "black" }}>{t('accountingEmployees7')}</p>
                    </div>
                </div>
            </div>

            <div style={{ padding: "0 50px" }} className="container mt-5">
            <p style={{ fontSize: "27px" }} data-aos="zoom-in" className="howToAvtoText mb-5">ВОЗМОЖНОСТИ 1С: БУХГАЛТЕРИИ 8.3 </p>
                <div className="mt-3">
                    <CheckOutlined style={{ fontSize: "30px", margin: "0 10px 0 0", color: "rgba(22, 47, 92, 0.836)" }} />  <b style={{ fontSize: "25px", fontWeight: "bold", color: "rgba(22, 47, 92, 0.836)" }}>
                        Легкий старт
                    </b>
                    <p style={{ fontSize: "20px", color: "black", marginLeft: "40px" }}>
                        В программу встроена система подсказок поможет заполнить данные о компании, настроить параметры учета и сразу приступить к работе. После выполнения стартовых
                        настроек большая часть данных заполнится автоматически, что экономит время сотрудников и сокращает ошибки.
                    </p>

                </div>
                <div className="mt-5">
                    <CheckOutlined style={{ fontSize: "30px", margin: "0 10px 0 0", color: "rgba(22, 47, 92, 0.836)" }} />
                    <b style={{ fontSize: "25px", fontWeight: "bold", color: "rgba(22, 47, 92, 0.836)" }}>Все участки учета </b>
                    <p style={{ fontSize: "20px", color: "black", marginLeft: "40px" }}>
                        Программа автоматизирует все виды учета в коммерческих предприятиях и легко настраивается под особенности бизнеса.
                    </p>
                </div>
                <div className="mt-5">
                    <CheckOutlined style={{ fontSize: "30px", margin: "0 10px 0 0", color: "rgba(22, 47, 92, 0.836)" }} />
                    <b style={{ fontSize: "25px", fontWeight: "bold", color: "rgba(22, 47, 92, 0.836)" }}>Любая система налогообложения </b>
                    <p style={{ fontSize: "20px", color: "black", marginLeft: "40px" }}>
                        Вы можете использовать одну и ту же программу для ведения информационных баз нескольких организаций, для применения разных систем налогообложения.
                    </p>
                </div>
                <div className="mt-5">
                    <CheckOutlined style={{ fontSize: "30px", margin: "0 10px 0 0", color: "rgba(22, 47, 92, 0.836)" }} />
                    <b style={{ fontSize: "25px", fontWeight: "bold", color: "rgba(22, 47, 92, 0.836)" }}>Отчетность через Интернет </b>
                    <p style={{ fontSize: "20px", color: "black", marginLeft: "40px" }}>
                        «1С: Бухгалтерия 8.3» содержит все обязательные отчеты для представления в государственные органы. Все они соответствуют
                        государственным стандартам. При составлении отчетности программа проверит
                        корректность и полноту данных. Также встроен календарь бухгалтера, который напомнит о необходимости сдачи отчетности.
                    </p>
                </div>
                <div className="mt-5">
                    <CheckOutlined style={{ fontSize: "30px", margin: "0 10px 0 0", color: "rgba(22, 47, 92, 0.836)" }} />
                    <b style={{ fontSize: "25px", fontWeight: "bold", color: "rgba(22, 47, 92, 0.836)" }}>Строгое соответствие требованиям законодательства</b>
                    <p style={{ fontSize: "20px", color: "black", marginLeft: "40px" }}>
                        Программа создана в соответствии с нормами законодательства. При появлении изменений, программа своевременно обновляется.
                    </p>
                    <p></p>
                </div>
                <div className="mt-5">
                    <CheckOutlined style={{ fontSize: "30px", margin: "0 10px 0 0", color: "rgba(22, 47, 92, 0.836)" }} />
                    <b style={{ fontSize: "25px", fontWeight: "bold", color: "rgba(22, 47, 92, 0.836)" }}>Интеграция с внешними сервисами</b>
                    <p style={{ fontSize: "20px", color: "black", marginLeft: "40px" }}>
                        Вы можете легко интегрировать с другими системами, с сайтами и торговым оборудованием.
                    </p>
                </div>
                <div className="mt-5">
                    <CheckOutlined style={{ fontSize: "30px", margin: "0 10px 0 0", color: "rgba(22, 47, 92, 0.836)" }} />
                    <b style={{ fontSize: "25px", fontWeight: "bold", color: "rgba(22, 47, 92, 0.836)" }}>Конфигурация</b>
                    <p style={{ fontSize: "20px", color: "black", marginLeft: "40px" }}>
                        Помогает вести учет денежных документов, вносить и печатать платежки и требования, а также приходные и расходные ордера.
                    </p>
                </div>
            </div>

            <div className="container mb-4 mt-4">
            <p data-aos="zoom-in" className="howToAvtoText">БЕСПЛАТНО ПРИ ПОКУПКЕ</p>
                <div className="row mt-5" style={{ justifyContent: "center" }}>
                    <div data-aos="zoom-in" className="col-12 col-md-4 col-sm-6 col-lg-4 col-xl-4 col-xxl-4">
                        <div style={{ width: "100%" }} className="card forOurBizines">
                            <div className="mb-4" style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                                <FileProtectOutlined style={{ color: "rgba(22, 47, 92, 0.836)" }} />
                            </div>
                            <div className="card_titlerOur">
                                {t("freeBuy")}
                            </div>

                        </div>
                    </div>
                    <div data-aos="zoom-in" className="col-12 col-md-4 col-sm-6 col-lg-4 col-xl-4 col-xxl-4">
                        <div className="card forOurBizines">
                            <div className="mb-4" style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                                {/* <FormOutlined /> */}
                                <FormOutlined style={{ color: "rgba(22, 47, 92, 0.836)" }} />
                            </div>
                            <div className="card_titlerOur">
                                {t("freeBuy2")}
                            </div>

                        </div>
                    </div>
                    <div data-aos="zoom-in" className="col-12 col-md-4 col-sm-6 col-lg-4 col-xl-4 col-xxl-4">
                        <div className="card forOurBizines">
                            <div className="mb-4" style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                                {/* <BankOutlined /> */}
                                <CodeOutlined style={{ color: "rgba(22, 47, 92, 0.836)" }} />
                            </div>
                            <div className="card_titlerOur">
                                {t("freeBuy3")}
                            </div>

                        </div>
                    </div>
                    <div data-aos="zoom-in" className="col-12 col-md-4 col-sm-6 col-lg-4 col-xl-4 col-xxl-4">
                        <div className="card forOurBizines">
                            <div className="mb-4" style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                                <PhoneOutlined style={{ color: "rgba(22, 47, 92, 0.836)" }} />
                            </div>
                            <div className="card_titlerOur">
                                {t("freeBuy4")}
                            </div>

                        </div>
                    </div>
                    <div data-aos="zoom-in" className="col-12 col-md-4 col-sm-6 col-lg-4 col-xl-4 col-xxl-4">
                        <div className="card forOurBizines">
                            <div className="mb-4" style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                                <BookOutlined style={{ color: "rgba(22, 47, 92, 0.836)" }} />
                            </div>
                            <div className="card_titlerOur">
                                {t("freeBuy5")}
                            </div>

                        </div>
                    </div>
                    <div data-aos="zoom-in" className="col-12 col-md-4 col-sm-6 col-lg-4 col-xl-4 col-xxl-4">
                        <div className="card forOurBizines">
                            <div className="mb-4" style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                                <VideoCameraOutlined style={{ color: "rgba(22, 47, 92, 0.836)" }} />
                            </div>
                            <div className="card_titlerOur">
                                <Link to={"/our-services/1 video"}> {t("freeBuy6")}</Link>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div>
                <Contact />
            </div>
        </div>
    )
}

export default About1CMahsulotlariIn8;