import React from "react";
import { useTranslation } from "react-i18next";
import product1 from "../../assets/product1.png"
import Contact from "../contact/Contact";
import "./About.css"
import Kimsanov from "../../assets/KimsanovMurodjon.jpg"
import Toshpulatov from "../../assets/ToshpulatovMaruf.jpg"
import Guvohnoma from "../../assets/guvohnoma.jpg"
import { Helmet } from "react-helmet";

const About = () => {

    const { t } = useTranslation()

    return (
        <div>
            <Helmet>
                <title> 1c бўйича манфаатли йечимлар айнан шу йерда | MY PROGR@M</title>
                <meta name="description" content="Узбекистон буйича 1с программасини урнатамиз ва сифатли  хизмат корсатамиз. Корхонангизни биз билан автоматлаштиринг" />
            </Helmet>
            <div className="container-fluid forBachImgFather">
                <div className="row forBacground mb-5 ">
                    <div className="col-12">
                        <div className="ONEcBugalter" >
                            <div data-aos="zoom-in"><p className="oneCAccountingText">MY PROGRAM</p>
                                <p className="oneCAccountingText2">{t("companyTitle")}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <p data-aos="zoom-in" className="howToAvtoText mb-5">{t("value")}</p>
                <div className="row abilitiesPage">
                    <div className="col-md-6 col-sm-6 mb-3">
                        <div data-aos="zoom-in" className="card twoHeight shadow">
                            <div className="crad-title">{t("Honesty")}</div>
                            <div className="childTex mt-2"><div className="childTextIn">{t("HonestyTitle")}</div></div>
                        </div>
                    </div>
                    <div data-aos="zoom-in" className="col-md-6 col-sm-6 mb-3">
                        <div className="card twoHeight shadow">
                            <div className="crad-title">{t("Professionalism")}</div>
                            <div className="childTex mt-2"><div className="childTextIn">{t("ProfessionalismTitle")}</div></div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div data-aos="zoom-in" className="col-12 mt-2">
                        <div className="card text-center shadow" style={{ padding: "10px" }}>
                            <div className="crad-title">{t("cooperation")}</div>
                            <div className="childTex mt-2"><div className="childTextIn">{t("cooperationTitle")}</div></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <p data-aos="zoom-in" className="howToAvtoText mb-5">{t("CERTIFICATIONS")}</p>
                <div className="row">
                    <div data-aos="zoom-in" className="col-md-6 col-sm-6 sertifikatImages1 mb-2"><img className="img-fluid sertifikatImages" src={Kimsanov} alt="Kimsanov Murodjon" /></div>
                    <div data-aos="zoom-in" className="col-md-6 col-sm-6 sertifikatImages1 mb-2"><img className="img-fluid sertifikatImages" src={Toshpulatov} alt="Toshpulatov Marufjon" /></div>
                </div>
                <div className="row mt-5">
                    <div data-aos="zoom-in" className="col-12 sertifikatImages1 shadow">
                        <img className="img-fluid sertifikatImages" src={Guvohnoma} alt="Guvohnoma" />
                    </div>
                </div>
            </div>
            <div>
                <Contact />
            </div>
        </div>
    )
}

export default About;