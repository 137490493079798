import React,{useEffect} from "react";
import Contact from "../contact/Contact";
import { useTranslation } from "react-i18next";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import firmaBoshqaruvi1 from "../../assets/firmaBoshqaruvi1.png"
import firmaBoshqaruvi2 from "../../assets/firmaBoshqaruvi2.png"
import firmaBoshqaruvi3 from "../../assets/firmaBoshqaruvi3.png"
import firmaBoshqaruvi4 from "../../assets/firmaBoshqaruvi4.png"
import { FormOutlined, FileProtectOutlined, CodeOutlined, BookOutlined, VideoCameraOutlined, CheckOutlined, PhoneOutlined } from "@ant-design/icons"
import { Link } from "react-router-dom";

const FirmaBoshqaruviAbout = () => {

    const { t } = useTranslation()

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    return (
        <div>

            <div className="container ">
                <p data-aos="zoom-in" className="howToAvtoText">{t("howToAvtoText")}</p>
                <div className="row" style={{ justifyContent: "center" }}>
                    <div className="col-10 mt-5">
                        <Carousel>
                            <div>
                                <img className="img-fluid" src={firmaBoshqaruvi1} />
                            </div>
                            <div>
                                <img className="img-fluid" src={firmaBoshqaruvi2} />
                            </div>
                            <div>
                                <img className="img-fluid" src={firmaBoshqaruvi3} />
                            </div>
                            <div>
                                <img className="img-fluid" src={firmaBoshqaruvi4} />
                            </div>
                        </Carousel>
                    </div>
                </div>
            </div>

            <div className="container mt-5">
                <p style={{ fontSize: "27px" }} data-aos="zoom-in" className="howToAvtoText">{t('consultic8SecondTitle2')}</p>
                <div className="col-10">
                    <div className="mt-3 d-flex">
                        <p style={{ fontSize: "20px", color: "black", margin: "0 10px 0 0 " }}>- </p> <p style={{ fontSize: "20px", color: "black" }}>
                            повысить эффективность работы компании, решение предоставляет руководителям широкий спектр инструментов для управления;
                        </p>
                    </div>
                    <div className="mt-3 d-flex">
                        <p style={{ fontSize: "20px", color: "black", margin: "0 10px 0 0 " }}>- </p>  <p style={{ fontSize: "20px", color: "black" }}>
                            хранить все данные в единой информационной базе;
                        </p>
                    </div>
                    <div className="mt-3 d-flex">
                        <p style={{ fontSize: "20px", color: "black", margin: "0 10px 0 0 " }}>- </p>  <p style={{ fontSize: "20px", color: "black" }}>
                            вести бизнес-аналитику для принятия правильных управленческих решений;
                        </p>
                    </div>
                    <div className="mt-3 d-flex">
                        <p style={{ fontSize: "20px", color: "black", margin: "0 10px 0 0 " }}>- </p>  <p style={{ fontSize: "20px", color: "black" }}>
                            управлять взаимоотношением с клиентами и многое другое!
                        </p>
                    </div>
                </div>
            </div>

            <div style={{ padding: "0 50px" }} className="container mt-5">
                <p style={{ fontSize: "27px" }} data-aos="zoom-in" className="howToAvtoText mb-5">1С: УПРАВЛЕНИЕ НАШЕЙ ФИРМОЙ​  </p>
                <div className="mt-3">
                    <CheckOutlined style={{ fontSize: "30px", margin: "0 10px 0 0", color: "rgba(22, 47, 92, 0.836)" }} />  <b style={{ fontSize: "25px", fontWeight: "bold", color: "rgba(22, 47, 92, 0.836)" }}>
                        Легкий старт
                    </b>
                    <p style={{ fontSize: "20px", color: "black", marginLeft: "40px" }}>
                        Встроенная система «Быстрый старт» поможет быстро настроить программу под особенности управления и ведения учета в компании и сразу приступить к работе.
                    </p>

                </div>
                <div className="mt-5">
                    <CheckOutlined style={{ fontSize: "30px", margin: "0 10px 0 0", color: "rgba(22, 47, 92, 0.836)" }} />
                    <b style={{ fontSize: "25px", fontWeight: "bold", color: "rgba(22, 47, 92, 0.836)" }}>Ведение учета в одной базе  </b>
                    <p style={{ fontSize: "20px", color: "black", marginLeft: "40px" }}>
                        Решение обеспечивает ведение сквозного управленческого учета по всем организациям, входящим в компанию, либо по каждой отдельно.
                    </p>
                </div>
                <div className="mt-5">
                    <CheckOutlined style={{ fontSize: "30px", margin: "0 10px 0 0", color: "rgba(22, 47, 92, 0.836)" }} />
                    <b style={{ fontSize: "25px", fontWeight: "bold", color: "rgba(22, 47, 92, 0.836)" }}>Продажи </b>
                    <p style={{ fontSize: "20px", color: "black", marginLeft: "40px" }}>
                        Вы сможете вести учет торговых операций: формировать заказы, фиксировать оптовые и розничные продажи. «1С:УНФ» легко
                        интегрировать с интернет-магазином и управлять доставкой прямо из программы.
                    </p>
                </div>
                <div className="mt-5">
                    <CheckOutlined style={{ fontSize: "30px", margin: "0 10px 0 0", color: "rgba(22, 47, 92, 0.836)" }} />
                    <b style={{ fontSize: "25px", fontWeight: "bold", color: "rgba(22, 47, 92, 0.836)" }}>Запасы и склад  </b>
                    <p style={{ fontSize: "20px", color: "black", marginLeft: "40px" }}>
                        Решение поддерживает раздельный учет товаров, учет поступлений и перемещений. В программе удобно вести прием всех поступающих
                        на склад товаров с указанием количества и цен, проводить инвентаризацию.
                    </p>
                </div>
                <div className="mt-5">
                    <CheckOutlined style={{ fontSize: "30px", margin: "0 10px 0 0", color: "rgba(22, 47, 92, 0.836)" }} />
                    <b style={{ fontSize: "25px", fontWeight: "bold", color: "rgba(22, 47, 92, 0.836)" }}>Снабжение и закупки </b>
                    <p style={{ fontSize: "20px", color: "black", marginLeft: "40px" }}>
                        В программе вы сможете отслеживать цены поставщиков и выбирать лучшие условия закупки, формировать заказы поставщикам, фиксировать поступления товаров.
                    </p>
                    <p></p>
                </div>
                <div className="mt-5">
                    <CheckOutlined style={{ fontSize: "30px", margin: "0 10px 0 0", color: "rgba(22, 47, 92, 0.836)" }} />
                    <b style={{ fontSize: "25px", fontWeight: "bold", color: "rgba(22, 47, 92, 0.836)" }}>Сервис</b>
                    <p style={{ fontSize: "20px", color: "black", marginLeft: "40px" }}>
                        Включен функционал для учета оказанных услуг и проектных работ. Можно формировать заказы и наряд-заказы, фиксировать выполненные работы.
                    </p>
                </div>
                <div className="mt-5">
                    <CheckOutlined style={{ fontSize: "30px", margin: "0 10px 0 0", color: "rgba(22, 47, 92, 0.836)" }} />
                    <b style={{ fontSize: "25px", fontWeight: "bold", color: "rgba(22, 47, 92, 0.836)" }}>Производство</b>
                    <p style={{ fontSize: "20px", color: "black", marginLeft: "40px" }}>
                        Поддерживает ведение производственного учета, позволяет планировать производство, рассчитывать
                        себестоимость продукции, планировать загрузку ресурсов предприятия и др.
                    </p>
                </div>
                <div className="mt-5">
                    <CheckOutlined style={{ fontSize: "30px", margin: "0 10px 0 0", color: "rgba(22, 47, 92, 0.836)" }} />
                    <b style={{ fontSize: "25px", fontWeight: "bold", color: "rgba(22, 47, 92, 0.836)" }}>Управление финансами </b>
                    <p style={{ fontSize: "20px", color: "black", marginLeft: "40px" }}>
                        Вы сможете вести управленческий учет, получать управленческий баланс, анализировать финансовый результат работы компании.
                        Поддерживается учет движения денежных средств на расчетных счетах и в кассе, ведение платежного календаря.
                    </p>
                </div>
                <div className="mt-5">
                    <CheckOutlined style={{ fontSize: "30px", margin: "0 10px 0 0", color: "rgba(22, 47, 92, 0.836)" }} />
                    <b style={{ fontSize: "25px", fontWeight: "bold", color: "rgba(22, 47, 92, 0.836)" }}>Управление персоналом </b>
                    <p style={{ fontSize: "20px", color: "black", marginLeft: "40px" }}>
                        Вы сможете составлять штатное расписание, планировать нагрузку на сотрудников и оценивать эффективность их работы, контролировать
                        выполнение заданий и рассчитывать заработную плату на основе настраиваемых показателей.
                    </p>
                </div>
                <div className="mt-5">
                    <CheckOutlined style={{ fontSize: "30px", margin: "0 10px 0 0", color: "rgba(22, 47, 92, 0.836)" }} />
                    <b style={{ fontSize: "25px", fontWeight: "bold", color: "rgba(22, 47, 92, 0.836)" }}>СRM</b>
                    <p style={{ fontSize: "20px", color: "black", marginLeft: "40px" }}>
                        В «1С:УНФ» вы можете вести базу клиентов, фиксировать всю историю взаимоотношений с ними, планировать звонки, встречи, переговоры, загрузку
                        менеджеров. Поддерживается отчет «Воронка продаж» и другие виды аналитики.
                    </p>
                </div>
                <div className="mt-5">
                    <CheckOutlined style={{ fontSize: "30px", margin: "0 10px 0 0", color: "rgba(22, 47, 92, 0.836)" }} />
                    <b style={{ fontSize: "25px", fontWeight: "bold", color: "rgba(22, 47, 92, 0.836)" }}>Бизнес-аналитика </b>
                    <p style={{ fontSize: "20px", color: "black", marginLeft: "40px" }}>
                        Решение поддерживает ведение бизнес-аналитики для принятия точных управленческих решений: статистика продаж, контроль взаиморасчетов с поставщиками и покупателями, учет доходов. Вы сможете
                        получать важную информацию по работе компании, своевременно выявлять точки роста или негативную динамику.
                    </p>
                </div>
                <div className="mt-5">
                    <CheckOutlined style={{ fontSize: "30px", margin: "0 10px 0 0", color: "rgba(22, 47, 92, 0.836)" }} />
                    <b style={{ fontSize: "25px", fontWeight: "bold", color: "rgba(22, 47, 92, 0.836)" }}>Интеграция с «1С:Бухгалтерией 8» </b>
                    <p style={{ fontSize: "20px", color: "black", marginLeft: "40px" }}>
                        Индивидуальные предприниматели смогут сдавать отчетность и уплачивать налоги в программе, остальные — интегрировать «1С:УНФ» с «1С:Бухгалтерией 8».
                    </p>
                </div>
            </div>

            <div className="container mb-4 mt-4">
                <p data-aos="zoom-in" className="howToAvtoText">БЕСПЛАТНО ПРИ ПОКУПКЕ</p>
                <div className="row mt-5" style={{ justifyContent: "center" }}>
                    <div data-aos="zoom-in" className="col-12 col-md-4 col-sm-6 col-lg-4 col-xl-4 col-xxl-4">
                        <div style={{ width: "100%" }} className="card forOurBizines">
                            <div className="mb-4" style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                                <FileProtectOutlined style={{ color: "rgba(22, 47, 92, 0.836)" }} />
                            </div>
                            <div className="card_titlerOur">
                                {t("freeBuy")}
                            </div>

                        </div>
                    </div>
                   
                    
                    
                    <div data-aos="zoom-in" className="col-12 col-md-4 col-sm-6 col-lg-4 col-xl-4 col-xxl-4">
                        <div className="card forOurBizines">
                            <div className="mb-4" style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                                <BookOutlined style={{ color: "rgba(22, 47, 92, 0.836)" }} />
                            </div>
                            <div className="card_titlerOur">
                                {t("freeBuy5f")}
                            </div>

                        </div>
                    </div>
                   
                </div>
            </div>

            <div>
                <Contact />
            </div>
        </div>
    )
}

export default FirmaBoshqaruviAbout;