const translationRu = {
    main: "ГЛАВНАЯ",
    about: "О КОМПАНИИ",
    ourServices: "НАШИ УСЛУГИ",
    coursec: "КУРСЫ",
    contact: "КОНТАКТЫ",
    firstAuto: "Автоматизация вашего бизнеса в месте с нами",
    firstWhenBiznes: "Когда ваш бизнес нуждается в автоматизации?",
    inText1: "ВЫ ПРОПУСКАЕТЕ ВАЖНЫЕ ПОКАЗАТЕЛИ",
    inText2: "ФИНАНСОВЫЙ БЕСПОРЯДОК УНИЧТОЖАЕТ ВСЕ ДОХОДЫ",
    inText3: "СООБЩЕСТВО ПОХОРЕНО В РЕГУЛЯРНОЙ РАБОТЕ",
    inText4: "ВАШ БИЗНЕС В БЕСПОРЯДКЕ",
    inTextChild1: "Сотрудники тратят много времени на подготовку нужных вам отчетов одновременно!",
    inTextChild2: "Деньги тратятся хаотично и вы постоянно заполняете кассовые пробелы вручную",
    inTextChild3: "Сотрудники тратят много времени на рутинные задачи, что мешает им выполнять действительно важные задачи.",
    inTextChild4: "Бизнес-процессы в вашей компании не структурированы и не оптимизированы по строгому алгоритму.",
    howToHelp: "КАК МЫ МОЖЕМ ВАМ ПОМОЧЬ?",
    oneCAccountingText: "1C: БУХГАЛТЕРИЯ 8",
    oneCAccountingText2: "Самая популярная бухгалтерская программа в Узбекистане. Полностью автоматизирует бухгалтерский, налоговый учет и отчетность.",
    howToAvtoText: "1C: УПРАВЛЕНИЯ НАШЕЙ ФИРМОЙ",
    oneCAccountingText3: "Все, что вам нужно для автоматизации вашего малого и среднего бизнеса в одной программе.",
    howToAvtoText2: "1C: УПРАВЛЕНИЯ ТОРГОВЛЕЙ 11",
    oneCAccountingText3: "Современный инструмент повышения эффективности торговых компаний.",
    // CONTACT
    contactTilt: "Связаться с нами",
    // ABOUT
    companyTitle: "основана в 2023 году группой единомышленников. Компания MY PROGRAM помогает компаниям из самых разных сфер деятельности и отраслей работать с высокой рентабельностью, непрерывно адаптироваться к изменениям и расти, придерживаясь принципов устойчивого развития. Создает и внедряет новые, доступные и эффективные решения и возможности для бизнеса и общества.",
    Honesty: "Честность",
    HonestyTitle: "Мы всегда стремимся выполнять взятые на себя обязательства по отношению к клиентам, партнерам и сотрудникам.",
    Professionalism: "Профессионализм",
    ProfessionalismTitle: "Профессионализм для нас — это сочетание компетентности и ответственности. Компетентность — это опыт и постоянное повышение квалификации. Ответственность — это исполнение взятых на себя обязательств по срокам и по содержанию.",
    cooperation: "Ориентация на долгосрочные взаимоотношения",
    cooperationTitle: "При необходимости выбора Компания жертвует сиюминутными выгодами в пользу стратегических достижений. Мы уверены, что с клиентами, партнерами, сотрудниками надо поступать таким образом, чтобы отношения продолжались долго и были выгодны для обеих сторон. Мы инвестируем прибыль в развитие бизнеса, создавая основу для работы в будущем!",
    value: "НАШИ ЦЕННОСТИ, КОТОРЫХ ПРИДЕРЖИВАЕТСЯ КАЖДЫЙ СОТРУДНИК КОМПАНИИ",
    CERTIFICATIONS: "НАШИ СЕРТИФИКАТЫ",
    ourServicesFirst: "РЕШЕНИЕ ДЛЯ КРУПНЫХ И СРЕДНИХ БИЗНЕСОВ",
    ourServicesFirstTitle: "МЫ ПРЕДЛАГАЕМ ВСЕ УСЛУГИ, НЕОБХОДИМЫЕ ДЛЯ АВТОМАТИЗАЦИИ ВАШЕГО БИЗНЕСА",
    clitsenziya: `На данной странице представлен список лицензионных программ 1С, где вы можете выбрать и приобрести программы 1С у официального франчайзера «1С».`,
    roznitsa: "1С: РОЗНИЦА 8",
    roznitsaTitle: "Современный инструмент повышения эффективности розничной торговли.",
    documentAbarot: "1С: ДОКУМЕНТООБОРОТ",
    documentAbarotTitle: `Полный контроль над всей документацией. «1С:Документооборот» — это современная система для управления документами, бизнес-процессами и взаимодействием сотрудников.`,
    // xizmatlar
    avtomatBiznes: "АВТОМАТИЗИРУЙТЕ СВОЙ БИЗНЕС",
    oneCProduct: "ПРОГРАММНЫЕ ПРОДУКТЫ 1С",
    oneCSanoat: "ПРОМЫШЛЕННЫЕ РЕШЕНИЯ ОТ 1С",
    oneCWork: "НАУЧИТЕСЬ РАБОТАТЬ В 1С",
    oneCITC: "1С (ИТС) ПОДДЕРЖКА",
    oneCWeb: "СОЗДАНИЕ САЙТА",
    oneCDesign: "СОЗДАНИЕ ЛОГОТИПА И ДИЗАЙНА",
    oneCBitriks: "ВНЕДРЕНИЕ БИТРИКС 24",
    oneCLearnMore: "УЗНАТЬ БОЛЬШЕ",
    OvtomatBiznes: "ПРЕИМУЩЕСТВА АВТОМАТИЗАЦИИ БИЗНЕСА",
    howtoWorkWe: "КАК МЫ РАБОТАЕМ?",
    // sanoat yechimlari
    sanoatYechimiTitle: "Промышленные решения MY PROGRAM направлены на максимальное соответствие потребностям автоматизации наиболее важных для предприятий бизнес-процессов, позволяя снизить потребительские затраты благодаря тому, что поставляются в виде готовых решений.",
    first: "ПРОИЗВОДСТВО",
    second: "МАШИНОСТРОЕНИЕ",
    shird: "ФАРМАЦЕВТИКА",
    four: "ТЕКСТИЛЬ",
    five: "СТРОИТЕЛЬСТВО",
    six: "СТРАХОВАНИЕ",
    seven: "ЛИЗИНГ",
    eight: "ЛОГИСТИКА",
    nine: "ОБРАЗОВАНИЕ",
    ten: "ОБЩАЯ ЕДА",
    // 1c bugalteriya mahsulotlari
    consultic8: "1С: БУХГАЛТЕРИЯ 8 ДЛЯ УЗБЕКИСТАНА. ЭЛЕКТРОННАЯ ПОСТАВКА",
    consultic8SecondTitle: "ПРИОБРЕТЕНИЕ ПРОГРАММЫ «1С: БУХГАЛТЕРИЯ 8.3» ПОЗВОЛИТ:",
    consultic8SecondTitle2: "ПРИОБРЕТЕНИЕ ПРОГРАММЫ «1С: УПРАВЛЕНИЕ НАШЕЙ ФИРМОЙ» ПОЗВОЛИТ:",
    consultic8SecondTitle3: "ПРИОБРЕТЕНИЕ ПРОГРАММЫ «1С: УПРАВЛЕНИЕ ТОРГОВЛЕЙ 11 ПОЗВОЛИТ:",
    accountingEmployees:"облегчить работу сотрудников бухгалтерии — программа берет на себя 99% трудоемких расчетов и полностью автоматизирует учет;",
    accountingEmployees2:"автоматизировать формирование первичной документации;",
    accountingEmployees3:"формирование регламентированной отчетности (налоговая, статистическая, отчетность в фонды);",
    accountingEmployees4:"вести учет юридических лиц с разными системами налогообложения;",
    accountingEmployees5:"исключить ошибки в отчетности благодаря проверке данных и автозаполнению полей в отчетности;",
    accountingEmployees6:"вести учет в соответствии с требованиями законодательства;",
    accountingEmployees7:"анализировать деятельность компании и многое другое!",
    freeBuy:"2 ЧАСА ВВОДНОГО ИНСТРУКТАЖА ПРИ УСТАНОВКЕ ПРОГРАММЫ",
    freeBuy2:"НАСТРОЙКА БАНК-КЛИЕНТА. ЗАГРУЗКА ВЫПИСКИ В 1С",
    freeBuy3:"НАСТРОЙКА СИСТЕМЫЭДО / DIDOX",
    freeBuy4:"3 МЕСЯЦА СЕРВИСНОГО ОБСЛУЖИВАНИЯ",
    freeBuy5:"ПОЛНОЕ УЧЕБНОЕ ПОСОБИЕ ПО 1С: БУХГАЛТЕРИЯ 8",
    freeBuy5f:"ПОЛНОЕ УЧЕБНОЕ ПОСОБИЕ ПО 1С: УПРАВЛЕНИЕ НАШЕЙ ФИРМОЙ",
    freeBuy5S:"ПОЛНОЕ УЧЕБНОЕ ПОСОБИЕ ПО 1C: УПРАВЛЕНИЯ ТОРГОВЛЕЙ 11",
    freeBuy5R:"ПОЛНОЕ УЧЕБНОЕ ПОСОБИЕ ПО 1С:РОЗНИЦА 8",
    freeBuy6:"ВИДЕО-УРОКИ",
    erpi:"1С: ERP УПРАВЛЕНИЕ ПРЕДПРИЯТИЕМ 2",
    erpiDesc:"Флагманское решение «1С» для комплексной автоматизации многопрофильных предприятий.",
    // ITS Support
    its:"ИНФОРМАЦИОННО-ТЕХНОЛОГИЧЕСКОЕ СОПРОВОЖДЕНИЕ 1С",
    itsTitle:"1С: ИТС (Информационно-технологическое сопровождение) — это комплексная поддержка пользователей программ «1С». Заключив договор 1С:ИТС, вам будут доступны консультации, web-сервисы и материалы для комфортной и эффективной работы.",
    itsCardTitle:"ПОДДЕРЖКА ПРИ РАБОТЕ В 1С",
    itsCardTitle2:"ИНДИВИДУАЛЬНОЕ СОПРОВОЖДЕНИЕ",
    itsCardTitle3:"КВАЛИФИЦИРОВАННЫЕ СПЕЦИАЛИСТЫ ",
    itsCardTitle4:"ПАКЕТ СЕРВИСОВ 1С:ИТС ", 
    itsCardDesc:"По договору ИТС пользователям доступны круглосуточно сайты с последними версиями программ, услуги сопровождения «1С» (в зависимости от тарифа) и линия консультаций, которая позволит пользователю получать ежедневную помощь специалистов MY PROGRAM.",
    itsCardDesc2:"Для удобства наших клиентов мы предлагаем несколько вариантов сопровождения «1С». Возможен подбор индивидуального варианта сопровождения 1С для конкретного предприятия, а также реализация узкоспециальных отраслевых решений.",
    itsCardDesc3:"Все специалисты имеют профессиональные сертификаты фирмы «1С», а также опыт методической и практической работы с программами. Получайте удовольствие от общения с профессионалами",
    itsCardDesc4:"Подготовка, проверка и отправка отчетности в контролирующие органы, автоматическое заполнение реквизитов контрагентов, обмен электронными счетами-фактурами и другими документами с контрагентами и др.",

     //  BIZNESNI RIVOJLANTIRISH

     CrdBiznes1:"Рост оборотных средств за счет комплексной оптимизации компании.",
     CrdBiznes2:"Уменьшение кол-во ошибок благодаря снижению человеческого фактора.",
     CrdBiznes3:"Повышение производительности и эффективности работы.",
     CrdBiznes4:"Обеспечение безопасного хранения информации с ограничением доступа.",
     CrdBiznes5:"Повышение качества товаров и услуг.",
     CrdBiznes6:"Регулярный и прозрачный мониторинг.",
 
     //  BIZNESNI RIVOJLANTIRISH

}

export default translationRu;