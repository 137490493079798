import React, { useEffect } from "react";
import "./main.css"
import Aos from "aos";
import "aos/dist/aos.css"
import { useTranslation } from "react-i18next";
import { FileTextOutlined, StockOutlined, MehOutlined, SlidersOutlined } from "@ant-design/icons"
import Contact from "../contact/Contact";
import KompaniyaBoshqaruvi from "../../components/KompaniyaBoshqaruvi/KompaniyaBoshqaruvi";
import CBugalteriya from "../../components/1CBugalteriya/1CBugalteriya";
import SavdoniBoshqaruvi from "../../components/SavdoniBoshqaruvi/SavdoniBoshqaruvi";

const Main = () => {

    useEffect(() => {
        Aos.init();
    }, [])
    
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    const { t } = useTranslation()

    return (
        <div>
            <div className="container-fluid">
                <div className="row">
                    <div data-aos="zoom-in" className="col-12  firstMainText">
                        <div data-aos="zoom-in" className="in_firstEffect">{t("firstAuto")}</div>
                    </div>
                </div>
            </div >
            <p data-aos="zoom-in" className="howToAvtoText">{t("firstWhenBiznes")}</p>
            <div className="container-fluid mt-5">
                <div className="row">
                    <div className="col-md-6 col-sm-12 text-center mt-4 mb-5 inContainerFluidCol">
                        <div data-aos="zoom-in" className="howToAvtoTextChild"><FileTextOutlined /></div>
                        <div data-aos="zoom-in" className="titleStatistik">
                            <div className="inText">{t("inText1")}</div>
                        </div>
                        <div data-aos="zoom-in" className="childTex"><div className="childTextIn">{t("inTextChild1")}</div></div>
                    </div>
                    <div className="col-md-6 col-sm-12 text-center mt-4 mb-5 inContainerFluidCol">
                        <div data-aos="zoom-in" className="howToAvtoTextChild"><StockOutlined /></div>
                        <div data-aos="zoom-in" className="titleStatistik">
                            <div className="inText">{t("inText2")}</div>
                        </div>
                        <div data-aos="zoom-in" className="childTex"><div className="childTextIn">{t("inTextChild2")}</div></div>
                    </div>
                    <div className="col-md-6 col-sm-12 text-center mt-4 mb-5 inContainerFluidCol">
                        <div data-aos="zoom-in" className="howToAvtoTextChild"><MehOutlined /></div>
                        <div data-aos="zoom-in" className="titleStatistik">
                            <div className="inText">{t("inText3")}</div>
                        </div>
                        <div data-aos="zoom-in" className="childTex"><div className="childTextIn">{t("inTextChild3")}</div></div>
                    </div>
                    <div className="col-md-6 col-sm-12 text-center mt-4 mb-5 inContainerFluidCol">
                        <div data-aos="zoom-in" className="howToAvtoTextChild"><SlidersOutlined /></div>
                        <div data-aos="zoom-in" className="titleStatistik">
                            <div className="inText">{t("inText4")}</div>
                        </div>
                        <div data-aos="zoom-in" className="childTex"><div className="childTextIn">{t("inTextChild4")}</div></div>
                    </div>
                </div>
            </div>
            <div className="container-fluid forBachImgFather">
                <p data-aos="zoom-in" className="howToAvtoText">{t("howToHelp")}</p>
                <CBugalteriya />
            </div>
            <div className="container-fluid forBachImgFather">
                <p data-aos="zoom-in" className="howToAvtoText">{t("howToAvtoText")}</p>
                <KompaniyaBoshqaruvi />
            </div>
            <div className="container-fluid forBachImgFather">
                <p data-aos="zoom-in" className="howToAvtoText">{t("howToAvtoText2")}</p>
                <SavdoniBoshqaruvi/>
            </div>
            <div className="mb-4">
                <Contact />
            </div>
        </div>
    )
}

export default Main;